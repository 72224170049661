<div class="provider-details-container" *ngIf="provider">
  <mat-card appearance="outlined">
    <mat-card-content class="card-content">
      <mat-tab-group class="details-tab-group">
        <mat-tab label="Details">
          <div class="search-disciplines">
            <button
              mat-raised-button
              (click)="navigateToDisciplines(provider)"
              matTooltip="Search for disciplines"
              class="search-disciplines-button"
            >
              <mat-icon>search</mat-icon>
              Search for disciplines
            </button>
          </div>

          <div class="inline mt-10">
            <h4 *ngIf="provider?.status">
              <strong>Status: {{ provider?.status || "n/a" }} </strong>
            </h4>
          </div>

          <div class="inline" *ngIf="provider?.type">
            <mat-icon class="mat-icon-small">health_and_safety</mat-icon>
            <p><strong>Type:</strong> {{ provider?.type || "n/a" }}</p>
          </div>
          <div class="inline" *ngIf="getAddress(provider)">
            <mat-icon class="mat-icon-small">house</mat-icon>
            <p>
              <strong>Address:</strong>
              {{ getAddress(provider) }}
            </p>
          </div>

          <div class="inline" *ngIf="provider?.email">
            <mat-icon class="mat-icon-small">email</mat-icon>
            <p><strong>Email:</strong></p>
            <p>{{ provider.email || "n/a" }}</p>
          </div>

          <div class="inline">
            <mat-icon class="mat-icon-small">phone</mat-icon>
            <p><strong>Phone:</strong></p>
            <p>{{ provider.phone || "n/a" }}</p>
          </div>

          <div class="inline" *ngIf="provider?.fax">
            <mat-icon class="mat-icon-small">fax</mat-icon>
            <p><strong>Fax:</strong></p>
            <p>{{ provider.fax || "n/a" }}</p>
          </div>

          <div class="inline" *ngIf="provider?.accreditationNumber">
            <mat-icon class="mat-icon-small">numbers</mat-icon>
            <p>
              <strong>Accreditor:</strong>
              {{ provider.accreditationNumber || "n/a" }}
            </p>
          </div>

          <div class="inline" *ngIf="provider?.dateIssued">
            <mat-icon class="mat-icon-small">date_range</mat-icon>
            <p>
              <strong>Issued: </strong
              >{{ (provider.dateIssued | date : "MMMM d, y") || "n/a" }}
            </p>
            <p *ngIf="provider.dateClosed">
              -- <strong>Closed: </strong>
              {{ (provider.dateClosed | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>

          <p *ngIf="provider?.licenceEffectiveDate">
            <strong>Licence effective date:</strong>
            {{ (provider.licenceEffectiveDate | date : "MMMM d, y") || "n/a" }}
          </p>
          <p *ngIf="provider?.licenceExpiryDate">
            <strong>Licence expiry date:</strong>
            {{ (provider.licenceExpiryDate | date : "MMMM d, y") || "n/a" }}
          </p>

          <div class="inline mt-10" *ngIf="provider?.website?.regulatoryBody">
            <p>
              <strong>Regulatory Body: </strong
              >{{ provider.website.regulatoryBody || "n/a" }}
            </p>
            <a
              href="https://{{ provider.website.regulatoryBodyWebsite }}"
              matTooltip="https://{{ provider.website.regulatoryBodyWebsite }}"
              target="_blank"
              class="link-with-icon"
            >
              <mat-icon class="mat-icon-small">open_in_new</mat-icon>
            </a>
          </div>

          <div class="mt-10" *ngIf="provider.owner">
            <p class="title-section"><strong>Organization</strong></p>
            <div class="inline">
              <p *ngIf="provider.owner?.name">
                Name: {{ provider.owner?.name }}
              </p>
              <a
                href="{{ provider.url }}"
                matTooltip="{{ provider.url }}"
                target="_blank"
                class="link-with-icon"
              >
                <mat-icon class="mat-icon-small">open_in_new</mat-icon>
              </a>
            </div>

            <p *ngIf="provider.owner?.type">Type: {{ provider.owner?.type }}</p>

            <div *ngIf="provider.owner?.directors.length > 0" class="mt-10">
              <p class="title-section"><strong>Director(s):</strong></p>
              <ul class="horizontal-professional-list">
                <li *ngFor="let staff of provider.owner?.directors">
                  <mat-icon
                    class="concern-warning"
                    *ngIf="staff?.professional?.events?.length > 0"
                    >warning</mat-icon
                  >
                  <button
                    class="search-entity-button"
                    mat-raised-button
                    (click)="navigateToProfessional(staff.professional)"
                    matTooltip="Search for professional"
                  >
                    {{ staff.name }}
                  </button>

                  <div class="mt-10">
                    <p *ngIf="staff.profession">
                      Profession: {{ staff.profession }}
                    </p>
                    <p *ngIf="staff.position">Position: {{ staff.position }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div *ngIf="provider.staff.length > 0" class="mt-10">
            <p class="title-section"><strong>Staff:</strong></p>
            <ul class="horizontal-professional-list">
              <li *ngFor="let staff of provider.staff">
                <mat-icon
                  class="concern-warning"
                  *ngIf="staff?.professional?.events?.length > 0"
                  >warning</mat-icon
                >
                <button
                  class="search-entity-button"
                  mat-raised-button
                  matSuffix
                  (click)="navigateToProfessional(staff.professional)"
                  matTooltip="Search for professional"
                >
                  {{ staff.name }}
                </button>

                <div class="mt-10">
                  <p *ngIf="staff.position">Position: {{ staff.position }}</p>
                  <p *ngIf="staff.profession">
                    Profession: {{ staff.profession }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="last-updated">
            <p>
              Last updated:
              {{ (provider.lastUpdatedDate | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>
        </mat-tab>

        <mat-tab *ngIf="provider.hasConcerns">
          <ng-template mat-tab-label>
            <mat-icon class="concern-warning">warning</mat-icon> Concerns
          </ng-template>
          <div *ngFor="let event of provider.events">
            <div *ngFor="let document of event.documents">
              <app-safe-html-viewer
                [rawHtml]="document.content"
              ></app-safe-html-viewer>

              <div class="last-updated">
                <p>
                  Last updated:
                  {{ document.lastUpdatedDate | date : "MMMM d, y" }}
                </p>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
