import { Discipline } from './discipline';
import { PageResult } from './pageResult';
import { Professional } from './professional';
import { Provider } from './provider';
export class ProviderSearch {
  id?: number;
  name: string;
  accreditationNumber: string;
  address: string;
  benefits: string[] = [];
  provinces: string[] = [];
  professions: string[] = [];
  concerns: string = 'N';
  benefitSearchGroups: BenefitSearchGroup[] | null;

  pageNumber: number;
  pageSize: number;
  pageResult: PageResult;
}

export class BenefitSearchGroup {
  benefit: string | null;
  provinces: {
    province: string;
    professions: string[];
  }[];
}

export class LastSearchPerformed {
  queryParams?: {
    ben?: string;
    prof?: string;
    prov?: string;
    n?: string;
  };
  selectedItem?: Provider | Professional | Discipline;
}
