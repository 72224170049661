import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';

import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from './pages/common/footer/footer.component';
import { NavBarComponent } from './pages/common/nav-bar/nav-bar.component';
import { ProviderListComponent } from './pages/provider/provider-list/provider-list.component';
import { ProfessionalListComponent } from './pages/professional/professional-list/professional-list.component';
import { ProviderDetailsViewComponent } from './pages/provider/provider-details-view/provider-details-view.component';
import { ProfessionalDetailsViewComponent } from './pages/professional/professional-details-view/professional-details-view.component';

@NgModule({
  declarations: [
    AppComponent,
    ProviderListComponent,
    ProfessionalListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppMaterialModule,
    CommonModule,
    NavBarComponent,
    FooterComponent,
    ProviderDetailsViewComponent,
    ProfessionalDetailsViewComponent,
    MatSnackBarModule,
    MatTooltipModule,
    FormsModule,
  ],
  providers: [
    provideAnimationsAsync(),
    provideHttpClient(),
    DatePipe,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
