<div class="professional-list-page">
  <header>
    <mat-card appearance="outlined">
      <mat-card-content>
        <div class="paginator-container">
          <p><strong>Results:</strong></p>
          <mat-paginator
            #paginator
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 20, 50, 100]"
            aria-label="Select page of professionals"
          ></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </header>
  <main>
    <section
      class="results-list"
      [ngClass]="{
        expanded: !selectedProfessional
      }"
    >
      <mat-form-field
        class="filter-field"
        appearance="outline"
        *ngIf="matDataSource.data.length > 0"
      >
        <input
          #input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Filter the results"
        />
      </mat-form-field>

      <div class="table-container">
        <h2 class="message" *ngIf="loading">Loading</h2>

        <!-- Results -->
        <table
          *ngIf="matDataSource.data.length > 0"
          mat-table
          [dataSource]="matDataSource"
          matSort
          class="results-table"
          tabindex="0"
          (keydown)="onKeydown($event)"
        >
          <ng-container matColumnDef="results">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Primary details
            </th>
            <td
              mat-cell
              *matCellDef="let professional"
              [ngClass]="{
                'selected-row-indicator':
                  professional.id === selectedProfessional?.id
              }"
            >
              <div class="details-row-box">
                <div
                  class="box"
                  [ngClass]="{
                    'primary-data': selectedProfessional
                  }"
                >
                  <p>
                    <strong>{{ professional.name }}</strong>
                  </p>
                </div>

                <div class="box" *ngIf="!selectedProfessional">
                  <ul>
                    <li *ngFor="let position of professional.workplaces">
                      <p>Workplace: {{ position.provider.name }}</p>
                      <p *ngIf="position.position">
                        Position: {{ position.position }}
                      </p>
                    </li>
                  </ul>
                </div>

                <div class="box" *ngIf="!selectedProfessional">
                  <p>
                    Concerns:
                    {{
                      professional.status ===
                      "Can provide patient care - with conditions"
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </div>

                <div class="box" *ngIf="!selectedProfessional">
                  <p>
                    Registration number: {{ professional.registrationNumber }}
                  </p>
                  <p>{{ professional.authorizations }}</p>
                </div>

                <div class="box tr" *ngIf="selectedProfessional">
                  <p class="mt-10">
                    Concerns:
                    {{
                      professional.status ===
                      "Entitled to operate - with conditions"
                        ? "Yes"
                        : "No"
                    }}
                  </p>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="
              let professional;
              columns: displayedColumns;
              let i = index
            "
            (click)="openProfessionalDetails(professional, getActualIndex(i))"
          ></tr>
        </table>
      </div>
    </section>

    <mat-card
      appearance="outlined"
      class="details-container"
      *ngIf="selectedProfessional"
    >
      <div class="details-title">
        <div class="inline">
          <h3>{{ selectedProfessional.name }}</h3>
          <a
            href="{{ selectedProfessional.url }}"
            matTooltip="{{ selectedProfessional.url }}"
            target="_blank"
            class="link-with-icon"
          >
            <mat-icon class="mat-icon-small">open_in_new</mat-icon>
          </a>
        </div>
        <button
          mat-icon-button
          aria-label="Close"
          (click)="closeProfessionalDetails()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-card-content class="details-card-content">
        <app-professional-details-view
          [professional]="selectedProfessional"
        ></app-professional-details-view>
      </mat-card-content>
    </mat-card>
  </main>
</div>
