import {
  Professional,
  ProfessionalSearch,
  LastSearchPerformed,
} from '../../models';

export interface ProfessionalsState {
  professionalsSearchOptions: ProfessionalSearch;
  professionalsSelectedBenefit: string;
  professionalsResultsData: StructuredResultsData;
  selectedProfessionalDetails?: Professional;
  lastSearchPerformed: LastSearchPerformed;
}

export const initialProfessionalsState: ProfessionalsState = {
  professionalsSearchOptions: new ProfessionalSearch(),
  professionalsSelectedBenefit: '',
  professionalsResultsData: {},
  selectedProfessionalDetails: null,
  lastSearchPerformed: {
    queryParams: {
      ben: null,
      prof: null,
      prov: null,
      n: null,
    },
    selectedItem: null,
  },
};

export type StructuredResultsData = {
  [benefit: string]: {
    [province: string]: {
      [profession: string]: {
        content: Professional[];
        length: number;
        pageIndex: number;
        concerns: boolean;
      };
    };
  };
};
