<div
  class="provider-details-container"
  *ngIf="provider && currentView === 'provider'"
>
  <mat-card appearance="outlined">
    <mat-card-content class="flex-space-around">
      <div class="box">
        <div class="inline">
          <p><strong>Status:</strong></p>
          <p>{{ provider.status || "n/a" }}</p>
        </div>
        <p><strong>Type:</strong> {{ provider.type || "n/a" }}</p>

        <div
          class="inline"
          *ngIf="
            provider.address1 ||
            provider.address2 ||
            provider.address3 ||
            provider.address4
          "
        >
          <mat-icon class="mat-icon-small">house</mat-icon>
          <p>
            <strong>Address:</strong>
            {{ provider.address1 }}
            {{ provider.address2 }}
            {{ provider.address3 }}
            {{ provider.address4 }}
          </p>
        </div>

        <div class="inline">
          <mat-icon class="mat-icon-small">email</mat-icon>
          <p><strong>Email:</strong></p>
          <p>{{ provider.email || "n/a" }}</p>
        </div>

        <div class="inline">
          <mat-icon class="mat-icon-small">phone</mat-icon>
          <p><strong>Phone:</strong></p>
          <p>{{ provider.phone || "n/a" }}</p>
        </div>
        <div class="inline">
          <mat-icon class="mat-icon-small">fax</mat-icon>
          <p><strong>Fax:</strong></p>
          <p>{{ provider.fax || "n/a" }}</p>
        </div>

        <div class="inline">
          <mat-icon class="mat-icon-small">date_range</mat-icon>
          <p>
            <strong>Issued: </strong
            >{{ (provider.dateIssued | date : "MMMM d, y") || "n/a" }}
          </p>
          <p *ngIf="provider.dateClosed">
            -- <strong>Closed: </strong>
            {{ (provider.dateClosed | date : "MMMM d, y") || "n/a" }}
          </p>
        </div>

        <p>
          <strong>Acreditor #:</strong>
          {{ provider.accreditationNumber || "n/a" }}
        </p>
        <p>
          <strong>UID:</strong>
          {{ provider.uid || "n/a" }}
        </p>
        <p>
          <strong>Licence effective date:</strong>
          {{ (provider.licenceEffectiveDate | date : "MMMM d, y") || "n/a" }}
        </p>
        <p>
          <strong>Licence expiry date:</strong>
          {{ (provider.licenceExpiryDate | date : "MMMM d, y") || "n/a" }}
        </p>

        <div class="inline">
          <p>
            <strong>Regulatory Body: </strong
            >{{ provider.website.regulatoryBody || "n/a" }}
          </p>
          <a
            href="{{ provider.website.regulatoryBodyWebsite }}"
            matTooltip="{{ provider.website.regulatoryBodyWebsite }}"
            target="_blank"
            class="link-with-icon"
          >
            <mat-icon class="mat-icon-small">open_in_new</mat-icon>
          </a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card appearance="outlined">
    <mat-card-content class="organization-section">
      <div *ngIf="provider.owner">
        <p><strong>Organization</strong></p>
        <div class="inline">
          <p *ngIf="provider.owner?.name">Name: {{ provider.owner?.name }}</p>
          <a
            href="{{ provider.url }}"
            matTooltip="{{ provider.url }}"
            target="_blank"
            class="link-with-icon"
          >
            <mat-icon class="mat-icon-small">open_in_new</mat-icon>
          </a>
        </div>
        <p *ngIf="provider.owner?.type">Type: {{ provider.owner?.type }}</p>

        <div *ngIf="provider.owner?.directors.length > 0" class="mt-10">
          <p><strong>Director(s):</strong></p>
          <ul class="horizontal-professional-list">
            <li *ngFor="let professional of provider.owner?.directors">
              <p>
                <strong>{{ professional.name }}</strong>
              </p>
              <p *ngIf="professional.profession">
                Profession: {{ professional.profession }}
              </p>
              <p *ngIf="professional.position">
                Position: {{ professional.position }}
              </p>
              <a
                href="#"
                (click)="
                  viewProfessional(professional); $event.preventDefault()
                "
              >
                see details
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="provider.staff.length > 0" class="mt-10">
        <p><strong>Staff:</strong></p>
        <ul class="horizontal-professional-list">
          <li *ngFor="let professional of provider.staff">
            <p>
              <strong>{{ professional.name }}</strong>
            </p>
            <p *ngIf="professional.position">
              Position: {{ professional.position }}
            </p>
            <p *ngIf="professional.profession">
              Profession: {{ professional.profession }}
            </p>
            <a
              href="#"
              (click)="viewProfessional(professional); $event.preventDefault()"
            >
              see details
            </a>
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div
  class="professional-details-view-container"
  *ngIf="selectedProfessional && currentView === 'professional'"
>
  <div class="professional-details-title">
    <a
      href="{{ environment.baseUrl }}/professionals?n={{
        selectedProfessional.name
      }}"
      matTooltip="{{ environment.baseUrl }}providers?n={{
        selectedProfessional.name
      }}"
      target="_blank"
    >
      <h4>{{ selectedProfessional?.name }}</h4></a
    >

    <button mat-icon-button aria-label="Close" (click)="goBack()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ng-container>
    <app-professional-details-view
      [professional]="selectedProfessional?.professional"
    ></app-professional-details-view>
  </ng-container>
</div>
