import {
  Discipline,
  DisciplineSearch,
  LastSearchPerformed,
} from '../../models';

export interface DisciplinesState {
  disciplinesSearchOptions: DisciplineSearch;
  disciplinesSelectedBenefit: string;
  disciplinesResultsData: StructuredResultsData;
  selectedDisciplineDetails?: Discipline;
  lastSearchPerformed: LastSearchPerformed;
}

export const initialDisciplinesState: DisciplinesState = {
  disciplinesSearchOptions: new DisciplineSearch(),
  disciplinesSelectedBenefit: '',
  disciplinesResultsData: {},
  selectedDisciplineDetails: null,
  lastSearchPerformed: {
    queryParams: {
      ben: null,
      prof: null,
      prov: null,
      n: null,
    },
    selectedItem: null,
  },
};

export type StructuredResultsData = {
  [benefit: string]: {
    [province: string]: {
      [profession: string]: {
        content: Discipline[];
        length: number;
        pageIndex: number;
        concerns: boolean;
      };
    };
  };
};
