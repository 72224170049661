<div class="app-container">
  <header class="navbar">
    <nav-bar></nav-bar>
  </header>
  <main class="content">
    <div class="responsive-container">
      <router-outlet></router-outlet>
    </div>
  </main>
  <footer class="footer">
    <footer></footer>
  </footer>
</div>
