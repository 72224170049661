import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ProfessionalsState } from './professionals.state';

export const selectProfessionalsState =
  createFeatureSelector<ProfessionalsState>('professionals');

export const selectProfessionalsSearchOptions = createSelector(
  selectProfessionalsState,
  (state) => state.professionalsSearchOptions
);
export const selectProfessionalsSelectedBenefit = createSelector(
  selectProfessionalsState,
  (state) => state.professionalsSelectedBenefit
);

export const selectProfessionalsResultsByBenefit = (
  benefit: string,
  province: string = 'all',
  profession: string = 'all'
) =>
  createSelector(
    selectProfessionalsState,
    (state) =>
      state.professionalsResultsData[benefit]?.[province]?.[profession] || {
        content: [],
        length: 0,
        pageIndex: 0,
        concerns: false,
      }
  );

export const selectSelectedProfessionalDetails = createSelector(
  selectProfessionalsState,
  (state) => state.selectedProfessionalDetails
);

export const selectProfessionalsLastSearchPerformed = createSelector(
  selectProfessionalsState,
  (state) => state.lastSearchPerformed
);
