import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

import { ProviderService } from '../../../services/provider.service';
import { ProviderSearch } from '../../../models/providerSearch';
import { Provider } from '../../../models/provider';

@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrl: './provider-list.component.scss',
})
export class ProviderListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  matDataSource: MatTableDataSource<Provider> = new MatTableDataSource();
  providerSearchParam: ProviderSearch = new ProviderSearch();
  providerSearch: ProviderSearch = new ProviderSearch();
  loading: boolean = false;

  benefitsUnderConstruction: string[] = ['Dental', 'Vision'];
  // providers: Provider[] = [];
  selectedProvider?: Provider = null;
  selectedBenefit?: string = '';

  selectedBenefitTabIndex: number = 0;
  healthyStatus: boolean = true;

  selectedRowIndex: number = 0;
  displayedColumns: string[] = ['results'];

  constructor(
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.providerService
      .getProviderSearch()
      .subscribe((providerSearchResponse) => {
        // build search options from server
        this.providerSearchParam = providerSearchResponse;

        // -- this will need to change to groups by benefit, prov and prof will be dependent
        // add placeholders benefits for demo
        // TODO: remove or adjust this placeholder section benefitsUnderConstruction = ['Dental', 'Vision'];
        this.providerSearchParam.benefits = [
          ...providerSearchResponse.benefits,
          ...this.benefitsUnderConstruction,
        ];

        // check for search params
        this.loadProviderSearch();
      });
  }

  ngAfterViewInit() {
    this.matDataSource.paginator = this.paginator;
  }

  loadProviderSearch() {
    // read from external call - search params - This approach does not react to changes in query parameters after the component initializes
    const name: string = this.route.snapshot.queryParamMap.get('n');
    const accreditationNumber: string =
      this.route.snapshot.queryParamMap.get('no');
    const address: string = this.route.snapshot.queryParamMap.get('addr');
    const benefit: string = this.route.snapshot.queryParamMap.get('ben');
    const province: string = this.route.snapshot.queryParamMap.get('prov');
    const profession: string = this.route.snapshot.queryParamMap.get('prof');

    // sync benefit options in search params to the mat-tab-group control variable
    this.selectedBenefit = benefit || this.providerSearchParam.benefits[0];
    this.selectedBenefitTabIndex = this.providerSearchParam.benefits.indexOf(
      this.selectedBenefit
    );

    // loads to providerSearch eventually be used by search()
    this.providerSearch.name = name;
    this.providerSearch.accreditationNumber = accreditationNumber;
    this.providerSearch.address = address;
    this.providerSearch.benefits = benefit
      ? [benefit]
      : [this.providerSearchParam.benefits[0]];

    this.providerSearch.provinces = province ? [province] : [];
    this.providerSearch.professions = profession ? [profession] : [];
  }

  searchProviders(providerSearch: ProviderSearch) {
    this.loading = true;
    this.providerService
      .searchProviders(providerSearch)
      .subscribe((providers) => {
        // this.providers = providers;
        // when new results, update the selectedProvider
        // when no results, deselect provider from previous search
        if (this.selectedProvider && providers.length > 0) {
          this.selectedProvider = providers[0];
        } else {
          this.selectedProvider = null;
        }
        this.matDataSource.data = providers;
        this.matDataSource.paginator = this.paginator;
        this.loading = false;
      });
  }

  search() {
    this.searchProviders(this.providerSearch);
    this.setRoute();
  }

  clear() {
    this.providerSearch = new ProviderSearch();
    this.providerSearch.benefits = [this.selectedBenefit];
    this.setRoute();
  }

  private setRoute() {
    this.router.navigate([], {
      queryParams: {
        ben: this.providerSearch.benefits,
        prof: this.providerSearch.professions,
        prov: this.providerSearch.provinces,
        n: this.providerSearch.name,
      },
    });
  }

  onBenefitTabIndexChange(index: number): void {
    // mat-tab-group, the lifecycle hooks like ngOnInit for the tab's content will execute immediately upon initialization
    this.selectedBenefitTabIndex = index; // update tab index controller
    this.selectedBenefit = this.providerSearchParam.benefits[index];
    this.providerSearch.benefits = [this.selectedBenefit]; // setting search benefit
    this.search(); // performs initial search and update search params.
  }

  openDetails(provider: Provider, index: number): void {
    this.selectedProvider = provider;
    this.selectedRowIndex = index;
  }

  onCloseDetails(): void {
    this.selectedProvider = null;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matDataSource.filter = filterValue.trim().toLowerCase();

    if (this.matDataSource.paginator) {
      this.matDataSource.paginator.firstPage();
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.matDataSource.data.length === 0) return;

    const maxIndex = this.getMaxIndexOnCurrentPage();
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    if (event.key === 'ArrowDown') {
      this.selectedRowIndex = Math.min(this.selectedRowIndex + 1, maxIndex);
    } else if (event.key === 'ArrowUp') {
      this.selectedRowIndex = Math.max(this.selectedRowIndex - 1, startIndex);
    }

    const newSelectedProvider = this.matDataSource.data[this.selectedRowIndex];
    this.selectedProvider = newSelectedProvider;
  }

  getActualIndex(index: number): number {
    return this.paginator.pageIndex * this.paginator.pageSize + index;
  }

  private getMaxIndexOnCurrentPage(): number {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = Math.min(
      startIndex + this.paginator.pageSize - 1,
      this.matDataSource.data.length - 1
    );
    return endIndex;
  }
}
