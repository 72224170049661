import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { ProviderService } from '../../../services/provider.service';
import { Professional } from '../../../models/professional';
import { ProfessionalSearch } from '../../../models/professionalSearch';

@Component({
  selector: 'app-professional-list',
  templateUrl: './professional-list.component.html',
  styleUrl: './professional-list.component.scss',
})
export class ProfessionalListComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator') paginator: MatPaginator;
  matDataSource: MatTableDataSource<Professional> = new MatTableDataSource();

  professionalSearch: ProfessionalSearch;
  professionals: Professional[] = [];
  selectedProfessional?: Professional = null;
  selectedRowIndex: number = 0;
  loading: boolean = false;

  displayedColumns: string[] = ['results'];

  constructor(
    private providerService: ProviderService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const name: string = this.route.snapshot.queryParamMap.get('n');
    const accreditationNumber: string =
      this.route.snapshot.queryParamMap.get('no');
    const address: string = this.route.snapshot.queryParamMap.get('addr');
    const workplace: string = this.route.snapshot.queryParamMap.get('wp');
    const province: string = this.route.snapshot.queryParamMap.get('prov');
    const benefit: string = this.route.snapshot.queryParamMap.get('ben');
    const profession: string = this.route.snapshot.queryParamMap.get('prof');

    this.professionalSearch = new ProfessionalSearch();
    this.professionalSearch.name = name;
    this.professionalSearch.registrationNumber = accreditationNumber;
    this.professionalSearch.address = address;
    this.professionalSearch.workplace = workplace;
    this.professionalSearch.provinces = province ? [province] : null;
    this.professionalSearch.benefits = benefit ? [benefit] : null;
    this.professionalSearch.professions = profession ? [profession] : null;

    this.searchProfessionals(this.professionalSearch);
  }

  ngAfterViewInit() {
    this.matDataSource.paginator = this.paginator;
  }

  getAllProfessionals() {
    this.providerService.getAllProfessionals().subscribe((professionals) => {
      this.professionals = professionals;
      this.matDataSource.data = this.professionals;
      this.matDataSource.paginator = this.paginator;
    });
  }

  searchProfessionals(professionalSearch: ProfessionalSearch) {
    this.providerService
      .searchProfessionals(professionalSearch)
      .subscribe((professionals) => {
        this.professionals = professionals;
        // this.selectedProfessional = professionals[0];

        if (this.selectedProfessional && professionals.length > 0) {
          // when new results, update the selectedProfessional
          this.selectedProfessional = professionals[0];
        } else {
          // when no results, deselect provider from previous search
          this.selectedProfessional = null;
        }
        this.matDataSource.data = professionals;
        this.matDataSource.paginator = this.paginator;
        this.loading = false;
      });
  }

  openProfessionalDetails(professional: Professional, index: number): void {
    this.selectedProfessional = professional;
    this.selectedRowIndex = index;
  }

  closeProfessionalDetails(): void {
    this.selectedProfessional = null;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matDataSource.filter = filterValue.trim().toLowerCase();

    if (this.matDataSource.paginator) {
      this.matDataSource.paginator.firstPage();
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.matDataSource.data.length === 0) return;

    const maxIndex = this.getMaxIndexOnCurrentPage();
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    if (event.key === 'ArrowDown') {
      this.selectedRowIndex = Math.min(this.selectedRowIndex + 1, maxIndex);
    } else if (event.key === 'ArrowUp') {
      this.selectedRowIndex = Math.max(this.selectedRowIndex - 1, startIndex);
    }

    const newSelectedProfessional =
      this.matDataSource.data[this.selectedRowIndex];
    this.selectedProfessional = newSelectedProfessional;
  }

  getActualIndex(index: number): number {
    return this.paginator.pageIndex * this.paginator.pageSize + index;
  }

  getMaxIndexOnCurrentPage(): number {
    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
    const endIndex = Math.min(
      startIndex + this.paginator.pageSize - 1,
      this.matDataSource.data.length - 1
    );
    return endIndex;
  }
}
