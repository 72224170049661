import { Component } from '@angular/core';

import { Discipline } from '../../../models/discipline';
import { FilterTableComponent } from '../filter-table/filter-table.component';
import { SearchFormComponent } from '../search-form/search-form.component';
@Component({
  selector: 'app-discipline-list',
  standalone: true,
  imports: [SearchFormComponent, FilterTableComponent],
  templateUrl: './discipline-list.component.html',
  styleUrl: './discipline-list.component.scss',
})
export class DisciplineListComponent {
  title = 'Provider Validation Tool';
  searchData: Discipline[] = [];
  searchPerformed: boolean = false;

  onSearchResults(data: Discipline[]): void {
    this.searchData = data;
  }

  onSearchPerformed(performed: boolean): void {
    this.searchPerformed = performed;
  }
}
