import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

import { Discipline } from '../../../models/discipline';
import { DisciplineSearch } from '../../../models/disciplineSearch';
import { DisciplineService } from '../../../services/discipline.service';
@Component({
  selector: 'app-search-form',
  standalone: true,
  imports: [
    MatCardModule,
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatGridListModule,
  ],
  templateUrl: './search-form.component.html',
  styleUrl: './search-form.component.scss',
})
export class SearchFormComponent implements OnInit {
  @Output() searchResults = new EventEmitter<Discipline[]>();
  @Output() searchPerformed = new EventEmitter<boolean>();
  isLoading: boolean = true;
  disciplineSearch: DisciplineSearch;
  filteredProfessions: string[] = [];
  filteredProvinces: string[] = [];
  dateFrom: string | null = null;
  dateTo: string | null = null;
  searchedFormValues: any = {};

  constructor(private service: DisciplineService) {}

  ngOnInit() {
    this.service.getDisciplineSearch().subscribe((catalog) => {
      this.disciplineSearch = catalog;
      this.filteredProfessions = catalog.professions.sort();
      this.filteredProvinces = catalog.provinces.sort();
      this.isLoading = false;
    });
  }

  private isFormUnchanged(form: NgForm): boolean {
    return Object.keys(form.value).every(
      (key) => form.value[key] === this.searchedFormValues[key]
    );
  }

  updateBenefitSelected(event: { value: string[] }, form: NgForm): void {
    if (event.value.length > 0) {
      const benefitSearchGroupsSelected = event.value
        .map((selection) =>
          this.disciplineSearch.benefitSearchGroups.filter(
            (benefitGroup) => benefitGroup.benefit === selection
          )
        )
        .flat();

      this.filteredProfessions = [
        ...new Set(
          benefitSearchGroupsSelected.flatMap((item) => item.professions)
        ),
      ].sort();

      this.filteredProvinces = [
        ...new Set(
          benefitSearchGroupsSelected.flatMap((item) => item.provinces)
        ),
      ].sort();

      this.preserveValidPreselectedValues(form);
    } else {
      // if benefits unselected, set default professions and provinces
      this.filteredProfessions = this.disciplineSearch.professions;
      this.filteredProvinces = this.disciplineSearch.provinces;
    }
  }

  isFormValid(form: NgForm): boolean | null {
    return (
      form.dirty &&
      Object.keys(form.value).some((key) => !!form.value[key]) && // at least one field should have data
      !this.isFormUnchanged(form) // compare with previous state
    );
  }

  clearAll(form: NgForm): void {
    form.resetForm();
  }

  private preserveValidPreselectedValues(form: NgForm) {
    const { professions, provinces } = form.value;

    let validPreselectedProfessions = '';
    let validPreselectedProvinces = '';

    if (professions.length > 0) {
      validPreselectedProfessions = professions.filter((value: string) =>
        this.filteredProfessions.includes(value)
      );
    }

    if (provinces.length > 0) {
      validPreselectedProvinces = provinces.filter((value: string) =>
        this.filteredProvinces.includes(value)
      );
    }

    form.setValue({
      ...form.value,
      professions: validPreselectedProfessions || form.value.professions,
      provinces: validPreselectedProvinces || form.value.provinces,
    });
  }

  private transformEmptyValuesToNull(values: DisciplineSearch): void {
    Object.keys(values).forEach((key) => {
      if (!values[key] || !values[key].length) {
        values[key] = null;
      }
    });
  }

  onSubmitSearch(form: NgForm): void {
    const bodyData: DisciplineSearch = form.value;
    this.transformEmptyValuesToNull(bodyData);

    this.service.searchDisciplines(bodyData).subscribe({
      next: (data: Discipline[]) => {
        if (data?.length) {
          this.service.showNotification('Data fetched ', 'Close', 'success');
        } else {
          this.service.showNotification(
            'No data available for this search',
            'Close',
            'info'
          );
        }
        this.searchResults.emit(data);
      },
      error: (e) => {
        console.error(e);
      },
    });
    this.searchPerformed.emit(true);
    this.searchedFormValues = { ...bodyData };
  }
}
