import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {
  Subscription,
  switchMap,
  combineLatest,
  BehaviorSubject,
  tap,
  distinctUntilChanged,
} from 'rxjs';
import { Store } from '@ngrx/store';

import {
  Discipline,
  DisciplineSearch,
  LastSearchPerformed,
  BenefitSearchGroup,
} from '../../../models';

import {
  setDisciplinesSelectedBenefit,
  setDisciplinesResultsDataByBenefit,
  setSelectedDisciplineDetails,
  setDisciplinesLastSearchPerformed,
  setResetResultsByBenefit,
} from '../../../state/disciplines/disciplines.actions';
import {
  selectDisciplinesLastSearchPerformed,
  selectDisciplinesResultsByBenefit,
  selectDisciplinesSearchOptions,
  selectDisciplinesSelectedBenefit,
  selectSelectedDisciplineDetails,
} from '../../../state/disciplines/disciplines.selectors';
import { hideLoading, showLoading } from '../../../state/app/app.actions';
import { DisciplineService } from '../../../services/discipline.service';

@Component({
  selector: 'app-discipline-list',
  templateUrl: './discipline-list.component.html',
  styleUrl: './discipline-list.component.scss',
})
export class DisciplineListComponent implements OnInit {
  private subscription = new Subscription();
  private userInteracted = false;
  @ViewChild('paginator') paginator: MatPaginator;
  matDataSource: MatTableDataSource<Discipline> = new MatTableDataSource();
  disciplineSearch: DisciplineSearch = new DisciplineSearch();
  disciplineSearchParam: DisciplineSearch = new DisciplineSearch();

  updateResultsByProvince$ = new BehaviorSubject<string>('all');
  updateResultsByProfession$ = new BehaviorSubject<string>('all');
  paginatorState$ = new BehaviorSubject<any>({});

  loading: boolean = false;
  disciplines: Discipline[] = [];

  openDetailsByID?: number = null;
  selectedDiscipline: Discipline = null;
  selectedBenefitTabIndex: number = 0;
  selectedBenefit?: string = null;
  selectedProvince?: string = null;
  selectedProfession?: string = null;
  selectedName?: string = null;

  benefitSearchGroupsSelected: BenefitSearchGroup[];
  filteredProfessions: string[] = [];
  filteredProvinces: string[] = [];

  selectedRowIndex: number = 0;
  displayedColumns: string[] = ['results'];
  // showConcernsOnly = false;

  resultsMessage: string = '';
  initalSearchMessage: string = 'Begin a search';
  noResultsMessage: string = 'No results found';
  noResultsWithConcerns: string = `The result doesn't contain items with concerns`;

  initialPageIndex: number = 0;
  initialPageSize: number = 20;
  initialTotalElements: number = 0;

  lastSearchPerformed?: LastSearchPerformed = null;
  hasValidLastSearchPerformed: boolean = false;
  isServerSearch: boolean = false;
  findDisciplinesByStateParams: DisciplineSearch = null;
  isSearchByStateParams: boolean = false;

  constructor(
    private disciplineService: DisciplineService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.resultsMessage = this.initalSearchMessage;

    const getPreloadDataSub = combineLatest([
      this.store
        .select(selectDisciplinesSelectedBenefit)
        .pipe(distinctUntilChanged()),
      this.updateResultsByProvince$.pipe(distinctUntilChanged()),
      this.updateResultsByProfession$.pipe(distinctUntilChanged()),
    ])
      .pipe(
        switchMap(([benefit, province, profession]) => {
          this.selectedBenefit = benefit;
          this.resultsMessage = this.initalSearchMessage;
          return this.store.select(
            selectDisciplinesResultsByBenefit(
              benefit,
              province || 'all',
              profession || 'all'
            )
          );
        }),
        distinctUntilChanged((prev, curr) => {
          const isSameContentThanPrevious =
            JSON.stringify(prev?.content) === JSON.stringify(curr?.content);
          const isViewSameLenghtAsResults =
            this.disciplines.length === curr?.content?.length;

          return isSameContentThanPrevious && isViewSameLenghtAsResults;
        })
      )
      .subscribe((data) => {
        this.processResults(data);
      });

    const searchOptionsByBenefitSub = combineLatest([
      this.store
        .select(selectDisciplinesSearchOptions)
        .pipe(
          distinctUntilChanged(
            (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
          )
        ),
      this.store
        .select(selectDisciplinesSelectedBenefit)
        .pipe(distinctUntilChanged((prev, curr) => prev === curr)),
    ])
      .pipe(
        tap(([searchOptions, benefit]) => {
          this.selectedBenefit = benefit;
          if (searchOptions.provinces.length > 0) {
            this.disciplineSearchParam = searchOptions;
            this.selectedBenefitTabIndex =
              this.disciplineSearchParam.benefits.indexOf(benefit);
            this.updateFiltersByBenefitSelected();
          }
          return [];
        })
      )
      .subscribe();

    const selectedItemSub = this.store
      .select(selectSelectedDisciplineDetails)
      .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id))
      .subscribe((discipline) => {
        this.selectedDiscipline = discipline;
        this.scrollToSelectedRow(discipline);
      });

    const lastSearchPerformedSub = this.store
      .select(selectDisciplinesLastSearchPerformed)
      .pipe(distinctUntilChanged())
      .subscribe((lastSearchPerformed) => {
        this.lastSearchPerformed = lastSearchPerformed;
        this.hasValidLastSearchPerformed =
          !!this.lastSearchPerformed?.queryParams?.ben;
      });

    this.subscription.add(getPreloadDataSub);
    this.subscription.add(searchOptionsByBenefitSub);
    this.subscription.add(selectedItemSub);
    this.subscription.add(lastSearchPerformedSub);
    this.subscription.add(this.updateResultsByProvince$);
    this.subscription.add(this.updateResultsByProfession$);
  }

  ngAfterViewInit() {
    this.paginatorState$.subscribe((data) => {
      if (this.paginator) {
        this.paginator.length = data.length;
        this.paginator.pageIndex = data.pageIndex;
      }
    });
    this.loadByQueryParamsOrLastPerformed();
  }

  private processResults(data: any): void {
    this.disciplines = [];
    this.matDataSource.data = [];
    this.paginatorState$.next({
      length: 0,
      pageIndex: 0,
    });

    this.selectedProvince;
    if (this.hasMinimumSearchQueryParams) {
      if (data?.content?.length == 0 && this.isServerSearch) {
        this.resultsMessage = this.noResultsMessage;
        if (!!this.selectedDiscipline?.id) {
          this.onCloseDetails();
        }
        // } else if (
        //   (data?.content?.length == 0 || this.selectedName) &&
        //   !this.isServerSearch
        // ) {
        //   this.searchPaginatedDisciplines(true, false, true);
      } else {
        this.setRoute();
      }
      this.disciplines = data.content || [];
      this.matDataSource.data = data.content;
      // this.showConcernsOnly = this.isSearchFromOtherPage
      // ? false
      // : data.concerns;
      this.paginatorState$.next({
        length: data.length,
        pageIndex: data.pageIndex,
      });
      this.autoSelectItemDetails();
    }
  }

  private loadByQueryParamsOrLastPerformed() {
    this.isSearchByStateParams =
      history.state?.searchData?.isSearchByStateParams;
    this.findDisciplinesByStateParams = history.state?.searchData;

    this.openDetailsByID = Number(this.route.snapshot.queryParamMap.get('id'));
    const benefit =
      this.route.snapshot.queryParamMap.get('ben') ||
      this.lastSearchPerformed?.queryParams?.ben;
    this.selectedProvince =
      this.route.snapshot.queryParamMap.get('prov') ||
      this.lastSearchPerformed?.queryParams?.prov;
    this.selectedProfession =
      this.isSearchByStateParams || this.openDetailsByID
        ? null
        : this.route.snapshot.queryParamMap.get('prof') ||
          this.lastSearchPerformed?.queryParams?.prof;

    this.selectedName =
      this.route.snapshot.queryParamMap.get('n') ||
      this.lastSearchPerformed?.queryParams?.n;

    if (!this.selectedBenefit || (benefit && benefit != this.selectedBenefit)) {
      const disciplinesSelectedBenefit = benefit
        ? benefit
        : this.disciplineSearchParam.benefits[0];

      this.store.dispatch(
        setDisciplinesSelectedBenefit({
          disciplinesSelectedBenefit,
        })
      );
    }

    const isDataInQueryParams =
      !!this.route.snapshot.queryParamMap.get('ben') ||
      !!this.route.snapshot.queryParamMap.get('prov') ||
      !!this.route.snapshot.queryParamMap.get('prof') ||
      !!this.route.snapshot.queryParamMap.get('n');

    if (
      isDataInQueryParams ||
      this.openDetailsByID ||
      this.isSearchByStateParams
    ) {
      this.searchPaginatedDisciplines(true);
    } else {
      this.setRoute();
      if (this.selectedProvince) {
        this.onProvinceSelected(this.selectedProvince);
      }
      if (this.selectedProfession) {
        this.onProfessionSelected(this.selectedProfession);
      }
    }
  }

  setSearchParams(): void {
    this.disciplineSearch.id = null;
    if (this.openDetailsByID) {
      this.disciplineSearch.id = this.openDetailsByID;
    }

    if (!this.selectedBenefit) {
      this.selectedBenefit =
        this.disciplineSearchParam.benefits[this.selectedBenefitTabIndex];
    }

    this.disciplineSearch.benefits = [this.selectedBenefit];
    this.disciplineSearch.provinces = [this.selectedProvince];
    this.disciplineSearch.professions = [this.selectedProfession];
    this.disciplineSearch.name = this.selectedName;
    // this.disciplineSearch.concerns = this.showConcernsOnly ? 'Y' : 'N';
    this.disciplineSearch.pageSize =
      this.paginator?.pageSize || this.initialPageSize;
    this.disciplineSearch.pageNumber =
      this.paginator?.pageIndex || this.initialPageIndex;

    this.disciplineSearch.provider =
      this.findDisciplinesByStateParams?.provider;
    this.disciplineSearch.professional =
      this.findDisciplinesByStateParams?.professional;
  }

  searchPaginatedDisciplines(
    isInitialSearch: boolean = false,
    triggerFromUI: boolean = false,
    hideSnackbar: boolean = false
  ): void {
    if (isInitialSearch) {
      this.loading = true;
      this.store.dispatch(showLoading());
      if (this.paginator) {
        this.paginator.pageIndex = 0;
      }
    }

    if (triggerFromUI) {
      this.resetControlFlags();
    }

    // let cacheConcernsAtSetParams = this.showConcernsOnly;
    this.setSearchParams();

    this.disciplineService
      .searchPaginatedDisciplines(this.disciplineSearch)
      .subscribe((disciplinesResults: DisciplineSearch) => {
        const content = disciplinesResults?.pageResult?.content;
        this.isServerSearch = true;
        this.store.dispatch(
          setDisciplinesResultsDataByBenefit({
            benefit: this.selectedBenefit,
            province: this.selectedProvince || 'all',
            profession: this.selectedProfession || 'all',
            disciplinesResultsData: content,
            length: disciplinesResults?.pageResult?.totalElements,
            pageIndex: this.paginator.pageIndex,
            // concerns: cacheConcernsAtSetParams,
            concerns: false,
          })
        );

        this.updateResultsByProvince$.next(this.selectedProvince);
        this.updateResultsByProfession$.next(this.selectedProfession);
        this.setRoute();

        if (isInitialSearch) {
          this.store.dispatch(hideLoading());
          this.loading = false;
          this.resultsMessage = this.noResultsMessage;
        }
      });
  }

  private autoSelectItemDetails() {
    const initialSelectedItem = this.selectedDiscipline;

    let newSelectedItem = null;
    if (this.openDetailsByID && this.disciplines.length > 0) {
      newSelectedItem =
        this.disciplines.find((p) => p.id === this.openDetailsByID) ??
        this.disciplines[0];
    } else if (this.isSearchByStateParams && this.disciplines.length > 0) {
      newSelectedItem = this.disciplines[0];
    } else if (
      initialSelectedItem ||
      !!this.lastSearchPerformed?.selectedItem?.id
    ) {
      newSelectedItem = !!this.lastSearchPerformed?.selectedItem?.id
        ? this.lastSearchPerformed.selectedItem
        : this.disciplines[0];
    } else {
      newSelectedItem = null;
    }

    if (initialSelectedItem?.id != newSelectedItem?.id) {
      this.dispatchSelectedItem(newSelectedItem);
    }
  }

  clearName() {
    this.selectedName = '';
    if (this.openDetailsByID) {
      this.openDetailsByID = null;
    }
    if (
      this.findDisciplinesByStateParams?.provider ||
      this.findDisciplinesByStateParams?.professional
    ) {
      this.isSearchByStateParams = false;
      this.findDisciplinesByStateParams = null;
    }
  }

  clear() {
    this.disciplineSearch = new DisciplineSearch();
    this.openDetailsByID = null;
    this.selectedBenefit = null;
    this.selectedName = null;
    this.selectedProvince = null;
    this.selectedProfession = null;
    this.resultsMessage = this.initalSearchMessage;
    this.disciplines = [];
    this.paginator.pageIndex = this.initialPageIndex;
    this.paginator.pageSize = this.initialPageSize;
    this.paginator.length = this.initialTotalElements;
    this.matDataSource.data = [];
    // this.showConcernsOnly = false;
    this.resetControlFlags();
    this.setRoute();
    this.resetResultsState();
  }

  private resetResultsState() {
    this.store.dispatch(setResetResultsByBenefit());
  }

  private setRoute() {
    const queryParams = {
      ben: this.selectedBenefit,
      prov: this.selectedProvince,
      prof: this.selectedProfession,
      n: this.selectedName || undefined,
      id: this.openDetailsByID || undefined,
    };

    this.store.dispatch(
      setDisciplinesLastSearchPerformed({
        lastSearchPerformed: {
          queryParams,
        },
      })
    );

    this.router.navigate([], {
      queryParams: queryParams,
    });
  }

  onBenefitTabIndexChange(index: number): void {
    const newBenefit = this.disciplineSearchParam.benefits[index];

    this.resetControlFlags();

    this.store.dispatch(
      setDisciplinesSelectedBenefit({
        disciplinesSelectedBenefit: newBenefit,
      })
    );
  }

  onUserInteractChange(): void {
    this.userInteracted = true;
  }

  onProvinceSelected(value: string): void {
    if (this.userInteracted) {
      this.resetControlFlags();
      this.selectedProfession = null;
      this.updateResultsByProfession$.next(null);
    }
    // this.filteredProfessions =
    //   this.benefitSearchGroupsSelected?.find((prov) => prov.province === value)
    //     ?.professions || [];
    this.filteredProfessions = [
      ...new Set(
        this.benefitSearchGroupsSelected?.flatMap((item) => item.professions)
      ),
    ].sort();

    this.updateResultsByProvince$.next(value);
  }

  private resetControlFlags() {
    this.userInteracted = false;
    this.isServerSearch = false;
    this.isSearchByStateParams = false;
    this.findDisciplinesByStateParams = null;
    this.onCloseDetails();
  }

  onProfessionSelected(value: string): void {
    if (this.userInteracted) {
      this.resetControlFlags();
    }
    this.updateResultsByProfession$.next(value);
  }

  openDetails(discipline: Discipline, index?: number): void {
    if (index || index == 0) {
      this.selectedRowIndex = this.getActualIndex(index);
    }

    this.scrollToSelectedRow(discipline);
    this.dispatchSelectedItem(discipline);
  }

  private dispatchSelectedItem(discipline) {
    this.store.dispatch(
      setSelectedDisciplineDetails({
        selectedDisciplineDetails: discipline,
      })
    );
    this.store.dispatch(
      setDisciplinesLastSearchPerformed({
        lastSearchPerformed: {
          selectedItem: discipline,
        },
      })
    );
  }

  scrollToSelectedRow(selectedItem): void {
    if (selectedItem) {
      setTimeout(() => {
        const rowElement = document.getElementById(`row-${selectedItem?.id}`);
        if (rowElement) {
          rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    }
  }

  onCloseDetails(): void {
    this.store.dispatch(
      setSelectedDisciplineDetails({
        selectedDisciplineDetails: null,
      })
    );

    this.store.dispatch(
      setDisciplinesLastSearchPerformed({
        lastSearchPerformed: {
          selectedItem: null,
        },
      })
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matDataSource.filter = filterValue.trim().toLowerCase();

    if (this.matDataSource.paginator) {
      this.matDataSource.paginator.firstPage();
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.matDataSource.data.length === 0) return;

    const getMaxIndexOnCurrentPage = (): number => {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const totalRecords = this.paginator.length;
      const endIndex = Math.min(
        startIndex + this.paginator.pageSize - 1,
        totalRecords - 1
      );
      return endIndex;
    };

    const performUpdateInStore = (selectedRowIndex: number) => {
      const relativeIndex = selectedRowIndex % this.paginator.pageSize;
      const newSelectedProvider = this.matDataSource.data[relativeIndex];
      this.dispatchSelectedItem(newSelectedProvider);
    };

    const currentStartIndex =
      this.paginator.pageIndex * this.paginator.pageSize;
    const maxIndex = getMaxIndexOnCurrentPage();

    if (event.key === 'ArrowDown') {
      if (this.selectedRowIndex < maxIndex) {
        this.selectedRowIndex++;
        performUpdateInStore(this.selectedRowIndex);
      }
    } else if (event.key === 'ArrowUp') {
      if (this.selectedRowIndex > currentStartIndex) {
        this.selectedRowIndex--;
        performUpdateInStore(this.selectedRowIndex);
      }
    }
  }

  getActualIndex(index: number): number {
    return this.paginator.pageIndex * this.paginator.pageSize + index;
  }

  private updateFiltersByBenefitSelected(): void {
    // const benefitSearchGroupsSelected =
    //   this.disciplineSearchParam.benefitSearchGroups?.filter(
    //     (benefitGroup) => benefitGroup.benefit === this.selectedBenefit
    //   )[0]?.provinces || [];
    // this.filteredProvinces =
    //   benefitSearchGroupsSelected?.map((prov) => prov?.province) || [];
    const benefitSearchGroupsSelected =
      this.disciplineSearchParam.benefitSearchGroups?.filter(
        (benefitGroup) => benefitGroup.benefit === this.selectedBenefit
      );

    this.filteredProvinces = [
      ...new Set(
        benefitSearchGroupsSelected?.flatMap((item) => item.provinces)
      ),
    ].sort();

    this.benefitSearchGroupsSelected = benefitSearchGroupsSelected;

    if (this.selectedProvince) {
      this.onProvinceSelected(this.selectedProvince);
    }
  }

  get hasMinimumSearchQueryParams(): boolean {
    return !!this.selectedProvince;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.store.dispatch(
      setSelectedDisciplineDetails({
        selectedDisciplineDetails: null,
      })
    );
  }
}
