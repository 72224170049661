import { createReducer, on } from '@ngrx/store';
import {
  setProfessionalsSearchOptions,
  setProfessionalsSelectedBenefit,
  setProfessionalsResultsByBenefit,
  setSelectedProfessionalDetails,
  setProfessionalsLastSearchPerformed,
  setResetResultsByBenefit,
} from './professionals.actions';
import { initialProfessionalsState } from './professionals.state';

export const professionalsReducer = createReducer(
  initialProfessionalsState,
  on(
    setProfessionalsSearchOptions,
    (state, { professionalsSearchOptions }) => ({
      ...state,
      professionalsSearchOptions,
    })
  ),
  on(
    setProfessionalsSelectedBenefit,
    (state, { professionalsSelectedBenefit }) => ({
      ...state,
      professionalsSelectedBenefit,
    })
  ),

  on(
    setProfessionalsResultsByBenefit,
    (
      state,
      {
        benefit,
        province,
        profession,
        professionalsResultsData,
        length,
        pageIndex,
        concerns,
      }
    ) => ({
      ...state,
      professionalsResultsData: {
        ...state.professionalsResultsData,
        [benefit]: {
          ...(state.professionalsResultsData[benefit] || {}),
          [province]: {
            ...(state.professionalsResultsData[benefit]?.[province] || {}),
            [profession]: {
              content: [...professionalsResultsData],
              length,
              pageIndex,
              concerns,
            },
          },
        },
      },
    })
  ),
  on(
    setSelectedProfessionalDetails,
    (state, { selectedProfessionalDetails }) => ({
      ...state,
      selectedProfessionalDetails,
    })
  ),
  on(setProfessionalsLastSearchPerformed, (state, { lastSearchPerformed }) => ({
    ...state,
    lastSearchPerformed: {
      ...state.lastSearchPerformed,
      ...lastSearchPerformed,
    },
  })),
  on(setResetResultsByBenefit, (state) => ({
    ...state,
    professionalsResultsData: {},
  }))
);
