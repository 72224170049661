import { createAction, props } from '@ngrx/store';
import { Provider, ProviderSearch, LastSearchPerformed } from '../../models';

export const setProvidersSelectedBenefit = createAction(
  '[Provider state] Set Provider Selected Benefit',
  props<{ providersSelectedBenefit: string }>()
);

export const setProvidersSearchOptions = createAction(
  '[Provider state] Set Provider Search Options',
  props<{ providersSearchOptions: ProviderSearch }>()
);

export const setProvidersResultsDataByBenefit = createAction(
  '[Provider state] Set Providers Results Data',
  props<{
    benefit: string;
    province?: string;
    profession?: string;
    providersResultsData: Provider[];
    length?: number;
    pageIndex?: number;
    concerns: boolean;
  }>()
);

export const setSelectedProviderDetails = createAction(
  '[Provider state] Set Selected Details',
  props<{ selectedProviderDetails: Provider }>()
);

export const setProvidersLastSearchPerformed = createAction(
  '[Provider state] Set last Search Performed',
  props<{
    lastSearchPerformed: LastSearchPerformed;
  }>()
);

export const setResetResultsByBenefit = createAction(
  '[Provider state] Set Reset saved Results'
);
