<mat-toolbar color="primary">
  <div class="responsive-container content">
    <h1 class="title">PVT</h1>
    <button mat-icon-button [matMenuTriggerFor]="menu" class="login-button">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item>
        <mat-icon>admin_panel_settings</mat-icon>
        <span>My Profile</span>
      </button>
      <button mat-menu-item>
        <mat-icon>card_membership</mat-icon>
        <span>Subscription</span>
      </button>
      <button mat-menu-item>
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
