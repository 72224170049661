import { createReducer, on } from '@ngrx/store';
import {
  setProvidersSearchOptions,
  setProvidersSelectedBenefit,
  setProvidersResultsDataByBenefit,
  setSelectedProviderDetails,
  setProvidersLastSearchPerformed,
  setResetResultsByBenefit,
} from './providers.actions';
import { initialProviderState } from './providers.state';

export const providersReducer = createReducer(
  initialProviderState,
  on(setProvidersSearchOptions, (state, { providersSearchOptions }) => ({
    ...state,
    providersSearchOptions,
  })),
  on(setProvidersSelectedBenefit, (state, { providersSelectedBenefit }) => ({
    ...state,
    providersSelectedBenefit,
  })),
  on(
    setProvidersResultsDataByBenefit,
    (
      state,
      {
        benefit,
        province,
        profession,
        providersResultsData,
        length,
        pageIndex,
        concerns,
      }
    ) => ({
      ...state,
      providersResultsData: {
        ...state.providersResultsData,
        [benefit]: {
          ...(state.providersResultsData[benefit] || {}),
          [province]: {
            ...(state.providersResultsData[benefit]?.[province] || {}),
            [profession]: {
              content: [...providersResultsData],
              length,
              pageIndex,
              concerns,
            },
          },
        },
      },
    })
  ),
  on(setSelectedProviderDetails, (state, { selectedProviderDetails }) => ({
    ...state,
    selectedProviderDetails,
  })),
  on(setProvidersLastSearchPerformed, (state, { lastSearchPerformed }) => ({
    ...state,
    lastSearchPerformed: {
      ...state.lastSearchPerformed,
      ...lastSearchPerformed,
    },
  })),
  on(setResetResultsByBenefit, (state) => ({
    ...state,
    providersResultsData: {},
  }))
);
