import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import { Professional } from '../../../models/professional';
import { environment } from '../../../../environments/environment';
import { selectProfessionalsSelectedBenefit } from '../../../state/professionals/professionals.selectors';
import { Provider } from '../../../models';
@Component({
  selector: 'app-professional-details-view',
  templateUrl: './professional-details-view.component.html',
  styleUrl: './professional-details-view.component.scss',
})
export class ProfessionalDetailsViewComponent {
  @Input() professional!: Professional;
  @Input() province!: string;

  private subscription = new Subscription();
  resolvedSelectedBenefit!: string;
  environment = environment;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    const benefitSub = this.store
      .select(selectProfessionalsSelectedBenefit)
      .subscribe((benefit) => {
        this.resolvedSelectedBenefit = benefit;
      });
    this.subscription.add(benefitSub);
  }

  navigateToDisciplines(professional: Professional) {
    this.router.navigate(['/disciplines'], {
      state: {
        searchData: {
          isSearchByStateParams: true,
          professional,
        },
      },
      queryParams: {
        ben: this.resolvedSelectedBenefit,
        prov: this.province,
        n: professional.name,
      },
    });
  }

  navigateToProvider(provider: Provider) {
    if (!provider) return;
    this.router.navigate(['/providers'], {
      queryParams: {
        ben: this.resolvedSelectedBenefit,
        prov: this.province,
        n: provider.name,
        id: provider.id,
      },
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
