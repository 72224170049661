import { createAction, props } from '@ngrx/store';
import {
  LastSearchPerformed,
  Professional,
  ProfessionalSearch,
} from '../../models';

export const setProfessionalsSelectedBenefit = createAction(
  '[Professionals state] Set Professional Selected Benefit',
  props<{ professionalsSelectedBenefit: string }>()
);

export const setProfessionalsSearchOptions = createAction(
  '[Provider state] Set Professional Search Options',
  props<{ professionalsSearchOptions: ProfessionalSearch }>()
);

export const setProfessionalsResultsByBenefit = createAction(
  '[Professionals state] Set Professionals Results Data',
  props<{
    benefit: string;
    province?: string;
    profession?: string;
    professionalsResultsData: Professional[];
    length?: number;
    pageIndex?: number;
    concerns: boolean;
  }>()
);

export const setSelectedProfessionalDetails = createAction(
  '[Professionals state] Set Selected Professional Details',
  props<{ selectedProfessionalDetails: Professional }>()
);

export const setProfessionalsLastSearchPerformed = createAction(
  '[Professionals state] Set last Search Performed',
  props<{ lastSearchPerformed: LastSearchPerformed }>()
);

export const setResetResultsByBenefit = createAction(
  '[Professionals state] Set Reset saved Results'
);
