import { createSelector, createFeatureSelector } from '@ngrx/store';
import { DisciplinesState } from './disciplines.state';

export const selectDisciplinesState =
  createFeatureSelector<DisciplinesState>('disciplines');

export const selectDisciplinesSearchOptions = createSelector(
  selectDisciplinesState,
  (state) => state.disciplinesSearchOptions
);

export const selectDisciplinesSelectedBenefit = createSelector(
  selectDisciplinesState,
  (state) => state.disciplinesSelectedBenefit
);

export const selectDisciplinesResultsByBenefit = (
  benefit: string,
  province: string = 'all',
  profession: string = 'all'
) =>
  createSelector(
    selectDisciplinesState,
    (state) =>
      state.disciplinesResultsData[benefit]?.[province]?.[profession] || {
        content: [],
        length: 0,
        pageIndex: 0,
        concerns: false,
      }
  );

export const selectSelectedDisciplineDetails = createSelector(
  selectDisciplinesState,
  (state) => state.selectedDisciplineDetails
);

export const selectDisciplinesLastSearchPerformed = createSelector(
  selectDisciplinesState,
  (state) => state.lastSearchPerformed
);
