<div class="provider-list-page">
  <header>
    <mat-card appearance="outlined" *ngIf="providerSearchParam">
      <mat-card-header>
        <mat-tab-group
          class="benefits-tabs"
          (selectedIndexChange)="onBenefitTabIndexChange($event)"
          [(selectedIndex)]="selectedBenefitTabIndex"
        >
          <mat-tab
            class="benefit-tab"
            [label]="benefit"
            *ngFor="let benefit of providerSearchParam.benefits"
          >
            <ng-template mat-tab-label>
              <mat-icon>folder</mat-icon>{{ benefit }}
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </mat-card-header>

      <mat-card-content>
        <div class="search-grid-container">
          <mat-form-field appearance="outline">
            <mat-label>Province</mat-label>
            <mat-select
              (click)="onUserInteractChange()"
              [(value)]="selectedProvince"
              (selectionChange)="onProvinceSelected($event.value)"
              [disabled]="['Vision', 'Dental'].includes(selectedBenefit)"
            >
              <mat-option
                *ngFor="let province of filteredProvinces"
                [value]="province"
                >{{ province }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Profession</mat-label>
            <mat-select
              (click)="onUserInteractChange()"
              [(value)]="selectedProfession"
              (selectionChange)="onProfessionSelected($event.value)"
              [disabled]="
                !hasMinimumSearchQueryParams ||
                ['Vision', 'Dental'].includes(selectedBenefit)
              "
            >
              <mat-option [value]="null">(All)</mat-option>
              <mat-option
                *ngFor="let profession of filteredProfessions"
                [value]="profession"
              >
                {{ profession }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="provider-name">
            <mat-label>Name</mat-label>
            <input
              matInput
              [(ngModel)]="selectedName"
              (keydown.enter)="searchPaginatedProviders(false, true)"
              [disabled]="
                !hasMinimumSearchQueryParams ||
                ['Vision', 'Dental'].includes(selectedBenefit)
              "
            />
            <button
              *ngIf="selectedName"
              class="clear-name-icon-button"
              mat-icon-button
              matSuffix
              aria-label="Clear"
              (click)="clearName()"
            >
              <mat-icon class="small-icon">close</mat-icon>
            </button>
          </mat-form-field>
          <div class="show-concerns-toggle">
            <p>Concerns</p>
            <mat-slide-toggle
              [(ngModel)]="showConcernsOnly"
              [disabled]="!hasMinimumSearchQueryParams"
            >
            </mat-slide-toggle>
          </div>
          <mat-card-actions class="search-actions">
            <button
              mat-icon-button
              class="search-button"
              (click)="searchPaginatedProviders(true, true)"
              [disabled]="
                !hasMinimumSearchQueryParams ||
                ['Vision', 'Dental'].includes(selectedBenefit)
              "
            >
              <mat-icon>search</mat-icon>
            </button>
            <button mat-button class="underlined-button" (click)="clear()">
              Clear search
            </button>
          </mat-card-actions>
        </div>
        <div class="paginator-container">
          <p><strong>Results:</strong></p>
          <mat-paginator
            #paginator
            [pageSizeOptions]="[20, 50, 100]"
            aria-label="Select page of providers"
            (page)="searchPaginatedProviders(false, true)"
            [disabled]="
              !selectedProvince ||
              this.paginator.length == 0 ||
              ['Vision', 'Dental'].includes(selectedBenefit)
            "
          ></mat-paginator>
        </div>
      </mat-card-content>
    </mat-card>
  </header>
  <main>
    <section
      class="results-list"
      [ngClass]="{
        expanded: !selectedProvider
      }"
    >
      <mat-form-field
        class="filter-field"
        appearance="outline"
        *ngIf="matDataSource.data.length > 0"
      >
        <input
          #input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Filter the results"
        />
      </mat-form-field>

      <div class="table-container">
        <!-- No Results -->
        <h2 class="message" *ngIf="matDataSource.data.length == 0 && !loading">
          {{ resultsMessage }}
        </h2>

        <!-- Results -->
        <table
          *ngIf="matDataSource.data.length > 0"
          mat-table
          [dataSource]="matDataSource"
          matSort
          class="results-table"
          tabindex="0"
          (keydown)="onKeydown($event)"
        >
          <ng-container matColumnDef="results">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              Results list
            </th>
            <td
              mat-cell
              *matCellDef="let provider"
              [ngClass]="{
                'selected-row-indicator': provider.id === selectedProvider?.id
              }"
            >
              <div class="details-row-box">
                <div
                  class="box"
                  [ngClass]="{
                    'primary-data': selectedProvider
                  }"
                >
                  <p>
                    <strong>{{ provider.name }}</strong>
                  </p>
                </div>

                <div class="box" *ngIf="!selectedProvider">
                  <p>{{ getAddress(provider) }}</p>
                </div>

                <div class="box centered" *ngIf="!selectedProvider">
                  <div class="inline">
                    <mat-icon class="mat-icon-small">phone</mat-icon>
                    <p>
                      {{ provider.phone }}
                    </p>
                  </div>
                  <div class="inline" *ngIf="provider.fax">
                    <mat-icon class="mat-icon-small">fax</mat-icon>
                    <p>
                      {{ provider.fax }}
                    </p>
                  </div>
                </div>

                <div class="box" *ngIf="!selectedProvider">
                  <p>
                    Concerns:
                    {{ provider.hasConcerns ? "Yes" : "No" }}
                  </p>
                  <p>
                    Status:
                    {{ provider.status || "n/a" }}
                  </p>
                </div>

                <div class="box tr" *ngIf="selectedProvider">
                  <p class="mt-10">
                    Concerns:
                    {{ provider.hasConcerns ? "Yes" : "No" }}
                  </p>
                </div>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            *matRowDef="let provider; columns: displayedColumns; let i = index"
            (click)="openDetails(provider, i)"
            [id]="'row-' + provider.id"
          ></tr>
        </table>
      </div>
    </section>

    <mat-card
      appearance="outlined"
      class="details-container"
      *ngIf="selectedProvider"
    >
      <div class="details-title">
        <div class="inline">
          <h3>{{ selectedProvider.name }}</h3>
          <a
            *ngIf="selectedProvider.url"
            href="{{ selectedProvider.url }}"
            matTooltip="{{ selectedProvider.url }}"
            target="_blank"
            class="link-with-icon"
          >
            <mat-icon class="mat-icon-small">open_in_new</mat-icon>
          </a>
        </div>
        <button mat-icon-button aria-label="Close" (click)="onCloseDetails()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <mat-card-content class="details-card-content">
        <provider-details-view
          [provider]="selectedProvider"
          [province]="selectedProvince"
        ></provider-details-view>
      </mat-card-content>
    </mat-card>
  </main>
</div>
