import { Provider, ProviderSearch, LastSearchPerformed } from '../../models';

export interface ProviderState {
  providersSearchOptions: ProviderSearch;
  providersSelectedBenefit?: string;
  providersResultsData: StructuredResultsData;
  selectedProviderDetails?: Provider;
  lastSearchPerformed: LastSearchPerformed;
}

export const initialProviderState: ProviderState = {
  providersSearchOptions: new ProviderSearch(),
  providersSelectedBenefit: '',
  providersResultsData: {},
  selectedProviderDetails: null,
  lastSearchPerformed: {
    queryParams: {
      ben: null,
      prof: null,
      prov: null,
      n: null,
    },
    selectedItem: null,
  },
};

export type StructuredResultsData = {
  [benefit: string]: {
    [province: string]: {
      [profession: string]: {
        content: Provider[];
        length: number;
        pageIndex: number;
        concerns: boolean;
      };
    };
  };
};
