<mat-card class="mat-elevation-z4" appearance="outlined">
  <mat-card-header>
    <div class="header-content">
      <mat-form-field class="filter-field" appearance="outline">
        <mat-label>Filter</mat-label>
        <input
          #input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Enter the text"
        />
      </mat-form-field>
    </div>
  </mat-card-header>

  <mat-card-content class="card-content">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">{{ row.name }}</td>
      </ng-container>

      <!-- Profession Column -->
      <ng-container matColumnDef="profession">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Profession</th>
        <td mat-cell *matCellDef="let row">{{ row.website.profession }}</td>
      </ng-container>

      <!-- Benefit Column -->
      <ng-container matColumnDef="benefit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Benefit</th>
        <td mat-cell *matCellDef="let row">{{ row.website.benefit }}</td>
      </ng-container>

      <!-- Regulatory Body Column -->
      <ng-container matColumnDef="regulatoryBody">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Regulatory Body
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.website.regulatoryBody }}
        </td>
      </ng-container>

      <!-- Discipline Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Discipline Type
        </th>
        <td mat-cell *matCellDef="let row">{{ row.type }}</td>
      </ng-container>

      <!-- Date Type Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let row">{{ row.date }}</td>
      </ng-container>

      <!-- Province Type Column -->
      <ng-container matColumnDef="province">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Province</th>
        <td mat-cell *matCellDef="let row">{{ row.website.province }}</td>
      </ng-container>

      <!-- Location Type Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
        <td mat-cell *matCellDef="let row">{{ row.location }}</td>
      </ng-container>

      <!-- Note Type Column -->
      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Note</th>
        <td mat-cell *matCellDef="let row">{{ row.note }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="openDialog(row)"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td
          class="mat-cell no-data-message-container"
          [colSpan]="displayedColumns.length"
        >
          <div *ngIf="dataSource.data.length === 0 && searchPerformed">
            <h2>No records found, please make a new search</h2>
          </div>
          <div *ngIf="dataSource.data.length === 0 && !searchPerformed">
            <h2>Please start a new search</h2>
          </div>

          <div *ngIf="dataSource.data.length > 0">
            <h2>No data matching the filter "{{ input.value }}"</h2>
          </div>
        </td>
      </tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [colSpan]="displayedColumns.length">
          No data to display
        </td>
      </tr>
    </table>
  </mat-card-content>

  <mat-card-footer class="paginator-container">
    <mat-paginator
      #paginator
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
      aria-label="Select page of providers"
    ></mat-paginator>
  </mat-card-footer>
</mat-card>
