import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {
  Subscription,
  switchMap,
  combineLatest,
  BehaviorSubject,
  tap,
  distinctUntilChanged,
} from 'rxjs';
import { Store } from '@ngrx/store';

import {
  Professional,
  ProfessionalSearch,
  LastSearchPerformed,
} from '../../../models';
import {
  setProfessionalsSelectedBenefit,
  setProfessionalsResultsByBenefit,
  setSelectedProfessionalDetails,
  setProfessionalsLastSearchPerformed,
  setResetResultsByBenefit,
} from '../../../state/professionals/professionals.actions';
import {
  selectProfessionalsSelectedBenefit,
  selectProfessionalsResultsByBenefit,
  selectSelectedProfessionalDetails,
  selectProfessionalsSearchOptions,
  selectProfessionalsLastSearchPerformed,
} from '../../../state/professionals/professionals.selectors';
import { hideLoading, showLoading } from '../../../state/app/app.actions';
import { ProviderService } from '../../../services/provider.service';

@Component({
  selector: 'app-professional-list',
  templateUrl: './professional-list.component.html',
  styleUrl: './professional-list.component.scss',
})
export class ProfessionalListComponent implements OnInit {
  private subscription = new Subscription();
  private userInteracted = false;
  @ViewChild('paginator') paginator: MatPaginator;
  matDataSource: MatTableDataSource<Professional> = new MatTableDataSource();
  professionalSearch: ProfessionalSearch = new ProfessionalSearch();
  professionalsSearchParam: ProfessionalSearch = new ProfessionalSearch();

  updateResultsByProvince$ = new BehaviorSubject<string>('all');
  updateResultsByProfession$ = new BehaviorSubject<string>('all');
  paginatorState$ = new BehaviorSubject<any>({});

  loading: boolean = false;
  professionals: Professional[] = [];

  openDetailsByID?: number = null;
  selectedProfessional?: Professional = null;
  selectedBenefitTabIndex: number = 0;
  selectedBenefit?: string = null;
  selectedProvince?: string = null;
  selectedProfession?: string = null;
  selectedName?: string = null;

  benefitSearchGroupsSelected: {
    province: string;
    professions: string[];
  }[];
  filteredProfessions: string[] = [];
  filteredProvinces: string[] = [];

  selectedRowIndex: number = 0;
  displayedColumns: string[] = ['results'];
  showConcernsOnly = false;

  resultsMessage: string = '';
  initalSearchMessage: string = 'Begin a search';
  noResultsMessage: string = 'No results found';
  noDBAvailableMessage: string = 'Database under construction';
  noResultsWithConcerns: string = `The result doesn't contain items with concerns`;

  initialPageIndex: number = 0;
  initialPageSize: number = 20;
  initialTotalElements: number = 0;

  lastSearchPerformed?: LastSearchPerformed = null;
  hasValidLastSearchPerformed: boolean = false;
  isServerSearch: boolean = false;
  isSearchFromDisciplines: boolean = false;

  constructor(
    private providerService: ProviderService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.resultsMessage = this.initalSearchMessage;

    const getPreloadDataSub = combineLatest([
      this.store
        .select(selectProfessionalsSelectedBenefit)
        .pipe(distinctUntilChanged()),
      this.updateResultsByProvince$.pipe(distinctUntilChanged()),
      this.updateResultsByProfession$.pipe(distinctUntilChanged()),
    ])
      .pipe(
        switchMap(([benefit, province, profession]) => {
          this.selectedBenefit = benefit;
          this.resultsMessage = ['Vision', 'Dental'].includes(benefit)
            ? this.noDBAvailableMessage
            : this.initalSearchMessage;
          return this.store.select(
            selectProfessionalsResultsByBenefit(
              benefit,
              province || 'all',
              profession || 'all'
            )
          );
        }),
        distinctUntilChanged((prev, curr) => {
          const isSameContentThanPrevious =
            JSON.stringify(prev?.content) === JSON.stringify(curr?.content);
          const isViewSameLenghtAsResults =
            this.professionals.length === curr?.content?.length;

          return isSameContentThanPrevious && isViewSameLenghtAsResults;
        })
      )
      .subscribe((data) => {
        this.processResults(data);
      });

    const searchOptionsByBenefitSub = combineLatest([
      this.store
        .select(selectProfessionalsSearchOptions)
        .pipe(
          distinctUntilChanged(
            (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
          )
        ),
      this.store
        .select(selectProfessionalsSelectedBenefit)
        .pipe(distinctUntilChanged((prev, curr) => prev === curr)),
    ])
      .pipe(
        tap(([searchOptions, benefit]) => {
          this.selectedBenefit = benefit;
          if (searchOptions.provinces.length > 0) {
            this.professionalsSearchParam = searchOptions;
            this.selectedBenefitTabIndex =
              this.professionalsSearchParam.benefits.indexOf(benefit);
            this.updateFiltersByBenefitSelected();
          }
          return [];
        })
      )
      .subscribe();

    const selectedProfessionalSub = this.store
      .select(selectSelectedProfessionalDetails)
      .pipe(distinctUntilChanged((prev, curr) => prev?.id === curr?.id))
      .subscribe((professional) => {
        this.selectedProfessional = professional;
        this.scrollToSelectedRow(professional);
      });

    const lastSearchPerformedSub = this.store
      .select(selectProfessionalsLastSearchPerformed)
      .pipe(distinctUntilChanged())
      .subscribe((lastSearchPerformed) => {
        this.lastSearchPerformed = lastSearchPerformed;
        this.hasValidLastSearchPerformed =
          !!this.lastSearchPerformed?.queryParams?.ben;
      });

    this.subscription.add(getPreloadDataSub);
    this.subscription.add(searchOptionsByBenefitSub);
    this.subscription.add(selectedProfessionalSub);
    this.subscription.add(lastSearchPerformedSub);
    this.subscription.add(this.updateResultsByProvince$);
    this.subscription.add(this.updateResultsByProfession$);
  }

  ngAfterViewInit() {
    this.paginatorState$.subscribe((data) => {
      if (this.paginator) {
        this.paginator.length = data.length;
        this.paginator.pageIndex = data.pageIndex;
      }
    });
    this.loadByQueryParamsOrLastPerformed();
  }

  private processResults(data: any): void {
    this.professionals = [];
    this.matDataSource.data = [];
    this.paginatorState$.next({
      length: 0,
      pageIndex: 0,
    });

    if (this.hasMinimumSearchQueryParams) {
      if (data?.content?.length == 0 && this.isServerSearch) {
        this.resultsMessage = this.noResultsMessage;
        if (!!this.selectedProfessional?.id) {
          this.onCloseDetails();
        }
        // } else if (
        //   (data?.content?.length == 0 || this.selectedName) &&
        //   !this.isServerSearch
        // ) {
        //   this.searchPaginatedProfessionals(true, false, true);
      } else {
        this.setRoute();
      }
      this.professionals = data.content || [];
      this.matDataSource.data = data.content;
      this.showConcernsOnly = this.isSearchFromOtherPage
        ? false
        : data.concerns;
      this.paginatorState$.next({
        length: data.length,
        pageIndex: data.pageIndex,
      });
      this.autoSelectItemDetails();
    }
  }

  private loadByQueryParamsOrLastPerformed() {
    this.isSearchFromDisciplines = history.state.searchFromDisciplines;
    this.openDetailsByID = Number(this.route.snapshot.queryParamMap.get('id'));
    const benefit =
      this.route.snapshot.queryParamMap.get('ben') ||
      this.lastSearchPerformed?.queryParams?.ben;
    this.selectedProvince =
      this.route.snapshot.queryParamMap.get('prov') ||
      this.lastSearchPerformed?.queryParams?.prov;
    this.selectedProfession = this.isSearchFromOtherPage
      ? null
      : this.route.snapshot.queryParamMap.get('prof') ||
        this.lastSearchPerformed?.queryParams?.prof;
    this.selectedName =
      this.route.snapshot.queryParamMap.get('n') ||
      this.lastSearchPerformed?.queryParams?.n;

    if (!this.selectedBenefit || (benefit && benefit != this.selectedBenefit)) {
      const professionalsSelectedBenefit = benefit
        ? benefit
        : this.professionalsSearchParam.benefits[0];

      this.store.dispatch(
        setProfessionalsSelectedBenefit({
          professionalsSelectedBenefit,
        })
      );
    }

    const isDataInQueryParams =
      !!this.route.snapshot.queryParamMap.get('ben') ||
      !!this.route.snapshot.queryParamMap.get('prov') ||
      !!this.route.snapshot.queryParamMap.get('prof') ||
      !!this.route.snapshot.queryParamMap.get('n');

    if (
      isDataInQueryParams ||
      this.openDetailsByID ||
      this.isSearchFromOtherPage
    ) {
      this.searchPaginatedProfessionals(true);
    } else {
      this.setRoute();
      if (this.selectedProvince) {
        this.onProvinceSelected(this.selectedProvince);
      }
      if (this.selectedProfession) {
        this.onProfessionSelected(this.selectedProfession);
      }
    }
  }

  setSearchParams(): void {
    this.professionalSearch.id = null;
    if (this.openDetailsByID) {
      this.professionalSearch.id = this.openDetailsByID;
    }

    if (!this.selectedBenefit) {
      this.selectedBenefit =
        this.professionalsSearchParam.benefits[this.selectedBenefitTabIndex];
    }

    this.professionalSearch.benefits = [this.selectedBenefit];
    this.professionalSearch.provinces = [this.selectedProvince];
    this.professionalSearch.professions = [this.selectedProfession];
    this.professionalSearch.name = this.selectedName;
    this.professionalSearch.concerns = this.showConcernsOnly ? 'Y' : 'N';
    this.professionalSearch.pageSize =
      this.paginator?.pageSize || this.initialPageSize;
    this.professionalSearch.pageNumber =
      this.paginator?.pageIndex || this.initialPageIndex;
  }

  searchPaginatedProfessionals(
    isInitialSearch: boolean = false,
    triggerFromUI: boolean = false,
    hideSnackbar: boolean = false
  ): void {
    if (isInitialSearch) {
      this.loading = true;
      this.store.dispatch(showLoading());
      if (this.paginator) {
        this.paginator.pageIndex = 0;
      }
    }

    if (triggerFromUI) {
      this.resetControlFlags();
    }

    let cacheConcernsAtSetParams = this.showConcernsOnly;
    this.setSearchParams();

    this.providerService
      .searchPaginatedProfessionals(this.professionalSearch)
      .subscribe((providersResults: ProfessionalSearch) => {
        const content = providersResults?.pageResult?.content;
        this.isServerSearch = true;

        this.store.dispatch(
          setProfessionalsResultsByBenefit({
            benefit: this.selectedBenefit,
            province: this.selectedProvince || 'all',
            profession: this.selectedProfession || 'all',
            professionalsResultsData: content,
            length: providersResults?.pageResult?.totalElements,
            pageIndex: this.paginator.pageIndex,
            concerns: cacheConcernsAtSetParams,
          })
        );

        this.updateResultsByProvince$.next(this.selectedProvince); // load UI via subsciption
        this.updateResultsByProfession$.next(this.selectedProfession); // load UI via subsciption
        this.setRoute();

        if (isInitialSearch) {
          this.store.dispatch(hideLoading());
          this.loading = false;
          this.resultsMessage = this.noResultsMessage;
        }
      });
  }

  private autoSelectItemDetails() {
    const initialSelectedItem = this.selectedProfessional;

    let newSelectedItem = null;
    if (this.openDetailsByID && this.professionals.length > 0) {
      newSelectedItem =
        this.professionals.find((p) => p.id === this.openDetailsByID) ??
        this.professionals[0];
    } else if (this.isSearchFromDisciplines && this.professionals.length > 0) {
      newSelectedItem = this.professionals[0];
    } else if (
      initialSelectedItem ||
      !!this.lastSearchPerformed?.selectedItem?.id
    ) {
      newSelectedItem = !!this.lastSearchPerformed?.selectedItem?.id
        ? this.lastSearchPerformed.selectedItem
        : this.professionals[0];
    } else {
      newSelectedItem = null;
    }

    if (initialSelectedItem?.id != newSelectedItem?.id) {
      this.dispatchSelectedItem(newSelectedItem);
    }
  }

  clearName() {
    this.selectedName = '';
    if (this.openDetailsByID) {
      this.openDetailsByID = null;
    }
  }

  clear() {
    this.professionalSearch = new ProfessionalSearch();
    this.openDetailsByID = null;
    this.selectedBenefit = null;
    this.selectedName = null;
    this.selectedProvince = null;
    this.selectedProfession = null;
    this.resultsMessage = this.initalSearchMessage;
    this.professionals = [];
    this.paginator.pageIndex = this.initialPageIndex;
    this.paginator.pageSize = this.initialPageSize;
    this.paginator.length = this.initialTotalElements;
    this.matDataSource.data = [];
    this.showConcernsOnly = false;
    this.resetControlFlags();
    this.setRoute();
    this.resetResultsState();
  }

  private resetResultsState() {
    this.store.dispatch(setResetResultsByBenefit());
  }

  private setRoute() {
    const queryParams = {
      ben: this.selectedBenefit,
      prov: this.selectedProvince,
      prof: this.selectedProfession,
      n: this.selectedName || undefined,
      id: this.openDetailsByID || undefined,
    };

    this.store.dispatch(
      setProfessionalsLastSearchPerformed({
        lastSearchPerformed: {
          queryParams,
        },
      })
    );

    this.router.navigate([], {
      queryParams,
    });
  }

  onBenefitTabIndexChange(index: number): void {
    const newBenefit = this.professionalsSearchParam.benefits[index];

    this.resetControlFlags();

    this.store.dispatch(
      setProfessionalsSelectedBenefit({
        professionalsSelectedBenefit: newBenefit,
      })
    );
  }

  onUserInteractChange(): void {
    this.userInteracted = true;
  }

  onProvinceSelected(value: string): void {
    if (this.userInteracted) {
      this.resetControlFlags();
      this.selectedProfession = null;
      this.updateResultsByProfession$.next(null);
    }

    this.filteredProfessions =
      this.benefitSearchGroupsSelected?.find((prov) => prov.province === value)
        ?.professions || [];

    this.updateResultsByProvince$.next(value);
  }

  private resetControlFlags() {
    this.userInteracted = false;
    this.isServerSearch = false;
    this.isSearchFromDisciplines = false;
    this.onCloseDetails();
  }

  onProfessionSelected(value: string): void {
    if (this.userInteracted) {
      this.resetControlFlags();
    }
    this.updateResultsByProfession$.next(value);
  }

  openDetails(professional: Professional, index?: number): void {
    if (index || index == 0) {
      this.selectedRowIndex = this.getActualIndex(index);
    }

    this.scrollToSelectedRow(professional);
    this.dispatchSelectedItem(professional);
  }

  private dispatchSelectedItem(professional) {
    this.store.dispatch(
      setSelectedProfessionalDetails({
        selectedProfessionalDetails: professional,
      })
    );
    this.store.dispatch(
      setProfessionalsLastSearchPerformed({
        lastSearchPerformed: {
          selectedItem: professional,
        },
      })
    );
  }

  scrollToSelectedRow(selectedItem): void {
    if (selectedItem) {
      setTimeout(() => {
        const rowElement = document.getElementById(`row-${selectedItem?.id}`);
        if (rowElement) {
          rowElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });
    }
  }

  onCloseDetails(): void {
    this.store.dispatch(
      setSelectedProfessionalDetails({
        selectedProfessionalDetails: null,
      })
    );

    this.store.dispatch(
      setProfessionalsLastSearchPerformed({
        lastSearchPerformed: {
          selectedItem: null,
        },
      })
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.matDataSource.filter = filterValue.trim().toLowerCase();

    if (this.matDataSource.paginator) {
      this.matDataSource.paginator.firstPage();
    }
  }

  onKeydown(event: KeyboardEvent): void {
    if (this.matDataSource.data.length === 0) return;

    const getMaxIndexOnCurrentPage = (): number => {
      const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
      const totalRecords = this.paginator.length;
      const endIndex = Math.min(
        startIndex + this.paginator.pageSize - 1,
        totalRecords - 1
      );
      return endIndex;
    };

    const performUpdateInStore = (selectedRowIndex: number) => {
      const relativeIndex = selectedRowIndex % this.paginator.pageSize;
      const newSelectedProvider = this.matDataSource.data[relativeIndex];
      this.dispatchSelectedItem(newSelectedProvider);
    };

    const currentStartIndex =
      this.paginator.pageIndex * this.paginator.pageSize;
    const maxIndex = getMaxIndexOnCurrentPage();

    if (event.key === 'ArrowDown') {
      if (this.selectedRowIndex < maxIndex) {
        this.selectedRowIndex++;
        performUpdateInStore(this.selectedRowIndex);
      }
    } else if (event.key === 'ArrowUp') {
      if (this.selectedRowIndex > currentStartIndex) {
        this.selectedRowIndex--;
        performUpdateInStore(this.selectedRowIndex);
      }
    }
  }

  private getActualIndex(index: number): number {
    return this.paginator.pageIndex * this.paginator.pageSize + index;
  }

  private updateFiltersByBenefitSelected(): void {
    const benefitSearchGroupsSelected =
      this.professionalsSearchParam.benefitSearchGroups?.filter(
        (benefitGroup) => benefitGroup.benefit === this.selectedBenefit
      )[0]?.provinces || [];
    this.filteredProvinces =
      benefitSearchGroupsSelected?.map((prov) => prov?.province) || [];
    this.benefitSearchGroupsSelected = benefitSearchGroupsSelected;

    if (this.selectedProvince) {
      this.onProvinceSelected(this.selectedProvince);
    }
  }

  get hasMinimumSearchQueryParams(): boolean {
    return !!this.selectedProvince;
  }

  get isSearchFromOtherPage(): boolean {
    return this.isSearchFromDisciplines || !!this.openDetailsByID;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.store.dispatch(
      setSelectedProfessionalDetails({
        selectedProfessionalDetails: null,
      })
    );
  }
}
