<div class="details-view-container" *ngIf="professional">
  <mat-card appearance="outlined">
    <mat-card-content class="card-content">
      <mat-tab-group class="details-tab-group">
        <mat-tab label="Details">
          <div class="search-disciplines">
            <button
              mat-raised-button
              (click)="navigateToDisciplines(professional)"
              matTooltip="Search for disciplines"
              class="search-disciplines-button"
            >
              <mat-icon>search</mat-icon>
              Search for disciplines
            </button>
          </div>
          <div class="inline mt-10">
            <h4 *ngIf="professional?.status">
              <strong>Status: {{ professional.status || "n/a" }}</strong>
            </h4>
          </div>
          <p *ngIf="professional?.preferredName">
            <strong>Preferred name:</strong>
            {{ professional.preferredName || "n/a" }}
          </p>
          <p *ngIf="professional?.previousNames">
            <strong>Previous name:</strong>
            {{ professional.previousNames || "n/a" }}
          </p>
          <p *ngIf="professional?.profession">
            <strong>Profession:</strong>
            {{ professional.profession || "n/a" }}
          </p>
          <p *ngIf="professional?.gender">
            <strong>Gender:</strong>
            {{ professional.gender || "n/a" }}
          </p>
          <p *ngIf="professional?.registrationNumber">
            <strong>Registration number:</strong>
            {{ professional.registrationNumber || "n/a" }}
          </p>
          <p *ngIf="professional?.registrationDate">
            <strong>Registration date:</strong>
            {{ professional.registrationDate || "n/a" }}
          </p>
          <p *ngIf="professional?.authorizations">
            <strong>Authorizations:</strong>
            {{ professional.authorizations || "n/a" }}
          </p>
          <p *ngIf="professional?.disciplines">
            <strong>Concerns / Disciplines:</strong>
            {{ professional.disciplines || "n/a" }}
          </p>
          <p *ngIf="professional?.licenceType">
            <strong>Licence type:</strong>
            {{ professional.licenceType || "n/a" }}
          </p>
          <p *ngIf="professional?.licenceExpiryDate">
            <strong>Licence expiry:</strong>
            {{ professional.licenceExpiryDate || "n/a" }}
          </p>
          <p *ngIf="professional?.licenceConditions">
            <strong>Licence conditions:</strong>
            {{ professional.licenceConditions || "n/a" }}
          </p>

          <div
            class="mt-10"
            *ngIf="
              professional?.workplaces?.length > 0 &&
              professional?.workplaces[0]?.provider
            "
          >
            <p class="title-section"><strong>Workplace:</strong></p>
            <ul class="horizontal-professional-list">
              <li *ngFor="let workplace of professional.workplaces">
                <button
                  class="search-entity-button"
                  mat-raised-button
                  (click)="navigateToProvider(workplace?.provider)"
                  matTooltip="Search for provider"
                >
                  {{ workplace.provider?.name }}
                </button>
                <div class="mt-10">
                  <p>
                    {{ workplace.provider.address1 }}
                    {{ workplace.provider.address2 }}
                  </p>
                  <p>{{ workplace.provider.address3 }}</p>
                  <p>{{ workplace.provider.address4 }}</p>
                  <p *ngIf="workplace.position">
                    Position: {{ workplace.position }}
                  </p>
                </div>
              </li>
            </ul>
          </div>

          <div *ngIf="professional.directorAts?.length > 0">
            <p class="title-section"><strong>Other workplaces:</strong></p>
            <ul class="horizontal-professional-list">
              <li *ngFor="let workplace of professional.directorAts">
                <div class="inline">
                  <button
                    class="search-entity-button"
                    mat-raised-button
                    (click)="navigateToProvider(workplace?.provider)"
                    matTooltip="Search for provider"
                  >
                    {{ workplace.provider?.name }}
                  </button>
                  <a
                    href="{{ workplace.provider.url }}"
                    matTooltip="{{ workplace.provider.url }}"
                  >
                    <mat-icon class="mat-icon-small">open_in_new</mat-icon>
                  </a>
                </div>
                <div class="mt-10">
                  <p *ngIf="workplace.position">
                    Position: {{ workplace.position }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div class="last-updated" *ngIf="professional?.lastUpdatedDate">
            <p>
              Last updated:
              {{ (professional.lastUpdatedDate | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>
        </mat-tab>

        <mat-tab *ngIf="professional.events?.length > 0">
          <ng-template mat-tab-label *ngIf="professional.events?.length > 0">
            <mat-icon class="concern-warning">warning</mat-icon> Concerns
          </ng-template>
          <div *ngFor="let event of professional.events">
            <p *ngIf="event.documents.length == 0">
              Concers details not available at this moment
            </p>
            <div *ngFor="let document of event.documents">
              <app-safe-html-viewer
                [rawHtml]="document.content"
              ></app-safe-html-viewer>
              <div class="last-updated">
                <p>
                  Last updated:
                  {{ document.lastUpdatedDate | date : "MMMM d, y" }}
                </p>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
