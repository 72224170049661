import { Component, Input, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';

import { Discipline, Professional, Provider } from './../../../models';
import { environment } from '../../../../environments/environment';
import { selectDisciplinesSelectedBenefit } from '../../../state/disciplines/disciplines.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'discipline-details-view',
  templateUrl: './discipline-details-view.component.html',
  styleUrl: './discipline-details-view.component.scss',
})
export class DisciplineDetailsViewComponent {
  selectedBenefit$: Observable<string>;
  environment = environment;
  private subscription = new Subscription();
  @Input() discipline!: Discipline;
  @Input() province!: string;

  issuedEntity: Provider | Professional;

  professionalInternalUrl: string = '';
  resolvedSelectedBenefit!: string;

  isProvider: boolean = false;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    this.buildIssuedEntity();
    const benefitSub = this.store
      .select(selectDisciplinesSelectedBenefit)
      .subscribe((benefit) => {
        this.resolvedSelectedBenefit = benefit;
      });

    this.subscription.add(benefitSub);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['discipline']) {
      this.buildIssuedEntity();
    }
  }

  buildIssuedEntity() {
    this.isProvider = !!this.discipline.provider;
    this.issuedEntity = this.isProvider
      ? this.discipline.provider
      : this.discipline.professional;
  }

  searchForIssuedEntity() {
    const queryParams = {
      ben: this.resolvedSelectedBenefit,
      prov: this.province,
      n: this.issuedEntity?.name || this.discipline.name,
      id: this.issuedEntity?.id,
    };

    const path = this.isProvider ? '/providers' : '/professionals';
    this.router.navigate([path], {
      state: { searchFromDisciplines: true },
      queryParams,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
