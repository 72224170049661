import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ProviderState } from './providers.state';

export const selectProvidersState =
  createFeatureSelector<ProviderState>('providers');

export const selectProvidersSearchOptions = createSelector(
  selectProvidersState,
  (state) => state.providersSearchOptions
);

export const selectProvidersSelectedBenefit = createSelector(
  selectProvidersState,
  (state) => state.providersSelectedBenefit
);

export const selectProvidersResultsByBenefit = (
  benefit: string,
  province: string = 'all',
  profession: string = 'all'
) =>
  createSelector(
    selectProvidersState,
    (state) =>
      state.providersResultsData[benefit]?.[province]?.[profession] || {
        content: [],
        length: 0,
        pageIndex: 0,
        concerns: false,
      }
  );

export const selectSelectedProviderDetails = createSelector(
  selectProvidersState,
  (state) => state.selectedProviderDetails
);

export const selectProvidersLastSearchPerformed = createSelector(
  selectProvidersState,
  (state) => state.lastSearchPerformed
);
