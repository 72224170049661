<mat-card appearance="outlined" class="card-container">
  <mat-card-header>
    <mat-card-title>
      <p>Search</p>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form
      class="search-form-container"
      #form="ngForm"
      (ngSubmit)="onSubmitSearch(form)"
    >
      <mat-grid-list
        cols="4"
        rowHeight="45px"
        gutterSize="10px"
        class="grid-container"
      >
        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Benefits</mat-label>
            <mat-select
              name="benefits"
              ngModel
              (selectionChange)="updateBenefitSelected($event, form)"
              multiple
            >
              <mat-option
                *ngFor="
                  let benefitGroup of disciplineSearch?.benefitSearchGroups
                "
                [value]="benefitGroup.benefit"
                >{{ benefitGroup.benefit }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Profession</mat-label>
            <mat-select multiple ngModel name="professions">
              <mat-option
                *ngFor="let profession of filteredProfessions"
                [value]="profession"
              >
                {{ profession }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Province</mat-label>
            <mat-select name="provinces" ngModel multiple>
              <mat-option
                *ngFor="let province of filteredProvinces"
                [value]="province"
                >{{ province }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Discipline Type</mat-label>
            <mat-select name="types" ngModel multiple>
              <mat-option
                *ngFor="let type of disciplineSearch?.types"
                [value]="type"
                >{{ type }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="2">
          <mat-form-field appearance="outline">
            <mat-label>Provider Name or Organization</mat-label>
            <input matInput name="name" ngModel />
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline">
            <mat-label>Regulatory Body</mat-label>
            <mat-select name="regulatoryBodies" ngModel multiple>
              <mat-option
                *ngFor="
                  let regulatoryBody of disciplineSearch?.regulatoryBodies
                "
                [value]="regulatoryBody"
                >{{ regulatoryBody }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </mat-grid-tile>

        <mat-grid-tile colspan="1">
          <mat-form-field appearance="outline" class="custom-datepicker-field">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                name="dateFrom"
                [ngModel]="dateFrom"
                placeholder="Start date"
              />
              <input
                matEndDate
                name="dateTo"
                [ngModel]="dateTo"
                placeholder="End date"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matPrefix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </mat-grid-tile>
      </mat-grid-list>

      <div class="action-buttons">
        <button
          mat-stroked-button
          color="accent"
          type="button"
          [disabled]="!form.dirty"
          (click)="clearAll(form)"
        >
          Clear all
        </button>

        <button
          mat-flat-button
          color="primary"
          type="submit"
          [disabled]="isLoading"
        >
          Search
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
