import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-dialog-view',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatIconModule],
  templateUrl: './dialog-view.component.html',
  styleUrl: './dialog-view.component.css',
})
export class DialogViewComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  openUrl(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
