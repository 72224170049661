import { createReducer, on } from '@ngrx/store';
import {
  setDisciplinesSearchOptions,
  setDisciplinesSelectedBenefit,
  setDisciplinesResultsDataByBenefit,
  setSelectedDisciplineDetails,
  setDisciplinesLastSearchPerformed,
  setResetResultsByBenefit,
} from './disciplines.actions';
import { initialDisciplinesState } from './disciplines.state';

export const disciplinesReducer = createReducer(
  initialDisciplinesState,
  on(setDisciplinesSearchOptions, (state, { disciplinesSearchOptions }) => ({
    ...state,
    disciplinesSearchOptions,
  })),
  on(
    setDisciplinesSelectedBenefit,
    (state, { disciplinesSelectedBenefit }) => ({
      ...state,
      disciplinesSelectedBenefit,
    })
  ),
  on(
    setDisciplinesResultsDataByBenefit,
    (
      state,
      {
        benefit,
        province,
        profession,
        disciplinesResultsData,
        length,
        pageIndex,
        concerns,
      }
    ) => ({
      ...state,
      disciplinesResultsData: {
        ...state.disciplinesResultsData,
        [benefit]: {
          ...(state.disciplinesResultsData[benefit] || {}),
          [province]: {
            ...(state.disciplinesResultsData[benefit]?.[province] || {}),
            [profession]: {
              content: [...disciplinesResultsData],
              length,
              pageIndex,
              concerns,
            },
          },
        },
      },
    })
  ),
  on(setSelectedDisciplineDetails, (state, { selectedDisciplineDetails }) => ({
    ...state,
    selectedDisciplineDetails,
  })),
  on(setDisciplinesLastSearchPerformed, (state, { lastSearchPerformed }) => ({
    ...state,
    lastSearchPerformed: {
      ...state.lastSearchPerformed,
      ...lastSearchPerformed,
    },
  })),
  on(setResetResultsByBenefit, (state) => ({
    ...state,
    disciplinesResultsData: {},
  }))
);
