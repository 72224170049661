import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { Provider } from '../models/provider';
import { ProviderSearch } from '../models/providerSearch';
import { Professional } from '../models/professional';
import { ProfessionalSearch } from '../models/professionalSearch';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class ProviderService {
  private url = environment.pvtUrl;

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  private log(message: string) {
    console.log(message);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return throwError(
        () => new Error(`Operation ${operation} failed: ${error.message}`)
      );
    };
  }

  getAllProviders(): Observable<Provider[]> {
    const url = `${this.url}/providers/all`;
    return this.http.get<Provider[]>(url).pipe(
      tap((_) => this.log(`getAllProviders`)),
      catchError(this.handleError<Provider[]>(`getAllProviders`))
    );
  }

  searchProviders(providerSearch: ProviderSearch): Observable<Provider[]> {
    const url = `${this.url}/providers/search`;
    return this.http
      .post<Provider[]>(
        url,
        this.cleanSearchParams(providerSearch),
        httpOptions
      )
      .pipe(
        tap((_) => this.log(`searchProviders`)),
        catchError(this.handleError<Provider[]>(`searchProviders`))
      );
  }

  getAllProfessionals(): Observable<Professional[]> {
    const url = `${this.url}/professionals/all`;
    return this.http.get<Professional[]>(url).pipe(
      tap((_) => this.log(`getAllProfessionals`)),
      catchError(this.handleError<Professional[]>(`getAllProfessionals`))
    );
  }

  searchProfessionals(
    professionalSearch: ProfessionalSearch
  ): Observable<Professional[]> {
    const url = `${this.url}/professionals/search`;
    return this.http
      .post<Professional[]>(url, professionalSearch, httpOptions)
      .pipe(
        tap((_) => this.log(`searchProfessionals`)),
        catchError(this.handleError<Professional[]>(`searchProfessionals`))
      );
  }

  getProviderSearch(): Observable<ProviderSearch> {
    const url = `${this.url}/providers/providerSearch`;
    return this.http.get<ProviderSearch>(url, httpOptions).pipe(
      tap((_) => this.log(`getProviderSearch`)),
      catchError(this.handleError<ProviderSearch>(`getProviderSearch`))
    );
  }

  private cleanSearchParams(params: ProviderSearch): ProviderSearch {
    const cleanParams = { ...params };
    Object.keys(cleanParams).forEach((key) => {
      if (cleanParams[key]?.length === 0) {
        cleanParams[key] = null;
      }
    });
    return cleanParams;
  }
}
