import { Website } from "./website";

export class ProfessionalSearch {
  name: string;
  registrationNumber: string;
  address: string;
  workplace: string;
  benefits: string[];
  provinces: string[];
  professions: string[];
}
