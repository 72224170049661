<div class="details-view-container">
  <mat-card appearance="outlined">
    <mat-card-content class="card-content">
      <mat-tab-group class="details-tab-group">
        <mat-tab label="Details">
          <div class="search-professional">
            <button
              class="search-entity-button"
              mat-raised-button
              (click)="searchForIssuedEntity()"
              [matTooltip]="
                'Search for ' + (isProvider ? 'Provider' : 'Professional')
              "
            >
              <mat-icon>search</mat-icon>
              {{ issuedEntity?.name || discipline?.name || "n/a" }}
            </button>
          </div>

          <h4 class="inline mt-10" *ngIf="discipline?.type">
            <strong> Type: {{ discipline?.type || "n/a" }}</strong>
            <a
              class="inline"
              *ngIf="discipline.link || issuedEntity?.url"
              href="{{ discipline.link || issuedEntity?.url }}"
              matTooltip="{{ discipline.link }}"
              target="_blank"
              class="link-with-icon"
            >
              <mat-icon class="mat-icon-small">open_in_new</mat-icon>
            </a>
          </h4>

          <div class="inline" *ngIf="issuedEntity?.status">
            <p>
              <strong>Status:</strong>
              {{ issuedEntity?.status }}
            </p>
          </div>

          <div
            class="inline"
            *ngIf="discipline.location || (issuedEntity?.['address3'])"
          >
            <p>
              <strong>Location:</strong>
              {{ discipline.location || (issuedEntity?.['address3'] || 'n/a') }}
            </p>
          </div>

          <div class="inline" *ngIf="discipline?.date">
            <p>
              <strong>Date: </strong
              >{{ (discipline?.date | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>

          <p *ngIf="discipline?.website?.province">
            <strong>Province:</strong>
            {{ discipline?.website?.province || "n/a" }}
          </p>
          <p *ngIf="discipline?.website?.benefit">
            <strong>Benefit:</strong>
            {{ discipline?.website?.benefit || "n/a" }}
          </p>
          <p *ngIf="discipline?.website?.profession">
            <strong>Profession:</strong>
            {{ discipline?.website?.profession || "n/a" }}
          </p>

          <div class="last-updated" *ngIf="discipline?.lastUpdatedDate">
            <p>
              Last updated:
              {{ (discipline?.lastUpdatedDate | date : "MMMM d, y") || "n/a" }}
            </p>
          </div>
        </mat-tab>

        <mat-tab *ngIf="discipline.documents?.length > 0">
          <ng-template mat-tab-label *ngIf="discipline?.documents?.length > 0">
            <mat-icon class="concern-warning">warning</mat-icon> Concerns
          </ng-template>
          <div *ngFor="let document of discipline.documents">
            <app-safe-html-viewer
              [rawHtml]="document.content"
            ></app-safe-html-viewer>

            <div class="last-updated">
              <p>
                Last updated:
                {{ document.lastUpdatedDate | date : "MMMM d, y" }}
              </p>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
