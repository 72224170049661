import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { Professional, Provider } from '../../../models';
import { environment } from '../../../../environments/environment';
import { selectProvidersSelectedBenefit } from '../../../state/providers/providers.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'provider-details-view',
  templateUrl: './provider-details-view.component.html',
  styleUrl: './provider-details-view.component.scss',
})
export class ProviderDetailsViewComponent {
  @Input() provider!: Provider;
  @Input() province!: string;

  private subscription = new Subscription();
  resolvedSelectedBenefit!: string;
  environment = environment;

  constructor(private store: Store, private router: Router) {}

  ngOnInit(): void {
    const benefitSub = this.store
      .select(selectProvidersSelectedBenefit)
      .subscribe((benefit) => {
        this.resolvedSelectedBenefit = benefit;
      });
    this.subscription.add(benefitSub);
  }

  navigateToDisciplines(provider: Provider) {
    if (!provider) return;

    this.router.navigate(['/disciplines'], {
      state: {
        searchData: {
          isSearchByStateParams: true,
          provider,
        },
      },
      queryParams: {
        ben: this.resolvedSelectedBenefit,
        prov: this.province,
        n: provider.name,
      },
    });
  }

  getAddress(provider: Provider) {
    const { address, address1, address2, address3, address4 } = provider;
    return !!address
      ? address
      : `${address1 || ''} ${address2 || ''} ${address3 || ''} ${
          address4 || ''
        }`;
  }

  navigateToProfessional(professional: Professional) {
    if (!professional) return;
    const queryParams = {
      ben: this.resolvedSelectedBenefit,
      prov: this.province,
      n: professional.name,
      id: professional.id,
    };

    this.router.navigate(['/professionals'], {
      queryParams,
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
