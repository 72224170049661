import { PageResult } from './pageResult';

export class ProfessionalSearch {
  id?: number;
  name: string;
  registrationNumber: string;
  address: string;
  workplace: string;
  benefits: string[] = [];
  provinces: string[] = [];
  professions: string[] = [];
  concerns: string = 'N';
  benefitSearchGroups: BenefitSearchGroup[] | null;

  pageNumber: number;
  pageSize: number;
  pageResult: PageResult;
}

export class BenefitSearchGroup {
  benefit: string | null;
  provinces: {
    province: string;
    professions: string[];
  }[];
}

export interface searchQueryParams {
  ben?: string;
  prof?: string;
  prov?: string;
  n?: string;
}
