<div class="details-view-container" *ngIf="professional">
  <mat-card appearance="outlined">
    <mat-card-content class="card-content">
      <p>
        <strong>Status:</strong>
        {{ professional.status || "N/A" }}
      </p>
      <p>
        <strong>UID:</strong>
        {{ professional.uid || "N/A" }}
      </p>
      <p>
        <strong>Preferred name:</strong>
        {{ professional.preferredName || "N/A" }}
      </p>
      <p>
        <strong>Previous name:</strong>
        {{ professional.previousNames || "N/A" }}
      </p>
      <p>
        <strong>Profession:</strong>
        {{ professional.profession || "N/A" }}
      </p>
      <p>
        <strong>Gender:</strong>
        {{ professional.gender || "N/A" }}
      </p>
      <p>
        <strong>Registration number:</strong>
        {{ professional.registrationNumber || "N/A" }}
      </p>
      <p>
        <strong>Registration date:</strong>
        {{ professional.registrationDate || "N/A" }}
      </p>
      <p>
        <strong>Training:</strong>
        {{ professional.authorizations || "N/A" }}
      </p>
      <p>
        <strong>Disciplines:</strong>
        {{ professional.disciplines || "N/A" }}
      </p>
      <p>
        <strong>Disciplines:</strong>
        {{ professional.disciplines || "N/A" }}
      </p>
      <p>
        <strong>Licence type:</strong>
        {{ professional.licenceType || "N/A" }}
      </p>
      <p>
        <strong>Licence expiry:</strong>
        {{ professional.licenceExpiryDate || "N/A" }}
      </p>
      <p>
        <strong>Licence conditions:</strong>
        {{ professional.licenceConditions || "N/A" }}
      </p>

      <div *ngIf="professional?.workplaces.length > 0">
        <p><strong>Workplace:</strong></p>
        <ul class="horizontal-professional-list">
          <li *ngFor="let workplace of professional.workplaces">
            <a
              href="{{ environment.baseUrl }}/providers?n={{
                workplace.provider.name
              }}"
              matTooltip="{{ environment.baseUrl }}providers?n={{
                workplace.provider.name
              }}"
              target="_blank"
              >{{ workplace.provider.name }}</a
            >
            <p>
              {{ workplace.provider.address1 }}
              {{ workplace.provider.address2 }}
            </p>
            <p>{{ workplace.provider.address3 }}</p>
            <p>{{ workplace.provider.address4 }}</p>
            <p *ngIf="workplace.position">Position: {{ workplace.position }}</p>
          </li>
        </ul>
      </div>

      <div *ngIf="professional.directorAts.length > 0">
        <p><strong>Other workplaces:</strong></p>
        <ul class="horizontal-professional-list">
          <li *ngFor="let workplace of professional.directorAts">
            <div class="inline">
              <p>{{ workplace.provider.name }}</p>
              <a
                href="{{ workplace.provider.url }}"
                matTooltip="{{ workplace.provider.url }}"
                target="_blank"
              >
                <mat-icon class="mat-icon-small">open_in_new</mat-icon>
              </a>
            </div>
            <p *ngIf="workplace.position">Position: {{ workplace.position }}</p>
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
</div>
