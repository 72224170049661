import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Discipline } from '../models/discipline';
import { DisciplineSearch } from '../models/disciplineSearch';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class DisciplineService {
  private url = environment.pvtUrl;

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  private log(message: string) {
    console.log(message);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      this.showNotification('Error fetching data', 'Close', 'error'), Infinity;
      return throwError(
        () => new Error(`Operation ${operation} failed: ${error.message}`)
      );
    };
  }

  showNotification(
    message: string,
    action: string,
    type: string = 'info',
    duration: number = 2000
  ) {
    this.snackBar.open(message, action, {
      duration,
      panelClass: type,
      horizontalPosition: 'end',
      verticalPosition: 'bottom',
    });
  }

  getAllDisciplines(): Observable<Discipline[]> {
    const url = `${this.url}/disciplines/all`;
    return this.http.get<Discipline[]>(url).pipe(
      tap((_) => this.log(`getAllDisciplines`)),
      catchError(this.handleError<Discipline[]>(`getDisciplines`))
    );
  }

  searchDisciplines(
    disciplineSearch: DisciplineSearch
  ): Observable<Discipline[]> {
    const url = `${this.url}/disciplines/search`;
    return this.http
      .post<Discipline[]>(url, disciplineSearch, httpOptions)
      .pipe(
        tap((_) => this.log(`searchDisciplines`)),
        catchError(this.handleError<Discipline[]>(`searchDisciplines`))
      );
  }

  getDisciplineSearch(): Observable<DisciplineSearch> {
    const url = `${this.url}/disciplines/disciplineSearch`;
    return this.http.get<DisciplineSearch>(url, httpOptions).pipe(
      tap((_) => this.log(`getDisciplineSearch`)),
      catchError(this.handleError<DisciplineSearch>(`getDisciplineSearch`))
    );
  }
}
