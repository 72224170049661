import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { Professional } from '../../../models/professional';
import { MatTooltipModule } from '@angular/material/tooltip';

import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-professional-details-view',
  standalone: true,
  imports: [CommonModule, MatCardModule, MatIconModule, MatTooltipModule],
  templateUrl: './professional-details-view.component.html',
  styleUrl: './professional-details-view.component.scss',
})
export class ProfessionalDetailsViewComponent {
  environment = environment;
  @Input() professional!: Professional;
}
