import { Provider } from '@angular/core';
import { Professional } from './professional';
import { PageResult } from './pageResult';

export class DisciplineSearch {
  id?: number;
  benefits: string[] | null = [];
  provinces: string[] | null = [];
  types: string[] | null;
  professions: string[] | null = [];
  regulatoryBodies: string[] | null;
  name: string | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  benefitSearchGroups: BenefitSearchGroup[] | null;

  professional: Professional;
  provider: Provider;

  pageNumber: number;
  pageSize: number;
  pageResult: PageResult;

  searchResult: SearchResult | null;
}

export class BenefitSearchGroup {
  benefit: string | null;
  professions: string[] | null;
  provinces: string[] | null;
}

export class SearchResult {
  events: any[];
}

export interface searchQueryParams {
  ben?: string;
  prof?: string;
  prov?: string;
  n?: string;
}
