import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Provider } from '../../../models/provider';
import { ProfessionalDetailsViewComponent } from '../../../pages/professional/professional-details-view/professional-details-view.component';
import { MatButtonModule } from '@angular/material/button';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'provider-details-view',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ProfessionalDetailsViewComponent,
    MatTooltipModule,
  ],
  templateUrl: './provider-details-view.component.html',
  styleUrl: './provider-details-view.component.scss',
})
export class ProviderDetailsViewComponent {
  environment = environment;
  @Input() provider!: Provider;

  currentView: 'provider' | 'professional' = 'provider';
  selectedProfessional = null;

  viewProfessional(professional: unknown): void {
    this.selectedProfessional = professional;
    this.currentView = 'professional';
  }

  goBack(): void {
    this.selectedProfessional = null;
    this.currentView = 'provider';
  }
}
