import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import { DialogViewComponent } from '../dialog-view/dialog-view.component';
import { Discipline } from '../../../models/discipline';

@Component({
  selector: 'app-filter-table',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
  ],
  templateUrl: './filter-table.component.html',
  styleUrl: './filter-table.component.scss',
})
export class FilterTableComponent {
  @Input() searchPerformed: boolean = false;
  @Input() set data(data: Discipline[]) {
    this.dataSource.data = data;
  }

  disciplines: Discipline[] = [];
  dataSource: MatTableDataSource<Discipline> = new MatTableDataSource();
  displayedColumns: string[] = [
    'name',
    'date',
    'profession',
    'benefit',
    'regulatoryBody',
    'type',
    'location',
    'province',
    'note',
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openDialog(row: any): void {
    this.dialog.open(DialogViewComponent, {
      maxWidth: '700px',
      data: row,
    });
  }
}
