<div class="dialog-container">
  <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
  <h2 mat-dialog-title>Discipline Details</h2>

  <mat-dialog-content class="mat-typography">
    <ul>
      <li><strong>ID: </strong>{{ data.id }}</li>
      <li><strong>Name: </strong>{{ data.name }}</li>
      <li><strong>Enabled: </strong>{{ data.website.enabled }}</li>
      <li><strong>Type: </strong>{{ data.type }}</li>
      <li *ngIf="data.website.benefit">
        <strong>Benefit: </strong>{{ data.website.benefit }}
      </li>
      <li *ngIf="data.website.profession">
        <strong>Profession: </strong>{{ data.website.profession }}
      </li>
      <li>
        <strong>Regulatory Body: </strong>{{ data.website.regulatoryBody }}
      </li>
      <li *ngIf="data.website.abbreviation">
        <strong>Abbreviation: </strong>{{ data.website.abbreviation }}
      </li>
      <li *ngIf="data.location">
        <strong>Location: </strong>{{ data.location }}
      </li>
      <li><strong>Date: </strong>{{ data.date || data.dateStr }}</li>
      <li *ngIf="data.note || data.note2">
        <strong>Notes: </strong>{{ data.note }}. {{ data.note2 }}
      </li>
    </ul>
    <div>
      <h3>Discipline sites</h3>
      <ul>
        <li *ngIf="data.link">
          <button mat-button color="accent" (click)="openUrl(data?.link)">
            Visit Link
          </button>
        </li>
        <li *ngIf="data.website.url">
          <button
            mat-button
            color="primary"
            (click)="openUrl(data?.website.url)"
          >
            Visit Website url
          </button>
        </li>
      </ul>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button color="accent" mat-dialog-close cdkFocusInitial>
      Close
    </button>
  </mat-dialog-actions>
  <div></div>
</div>
