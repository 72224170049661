import { createAction, props } from '@ngrx/store';
import {
  Discipline,
  DisciplineSearch,
  LastSearchPerformed,
} from '../../models';

export const setDisciplinesSelectedBenefit = createAction(
  '[Disciplines state] Set Disciplines Selected Benefit',
  props<{ disciplinesSelectedBenefit: string }>()
);

export const setDisciplinesSearchOptions = createAction(
  '[Disciplines state] Set Disciplines Search Options',
  props<{ disciplinesSearchOptions: DisciplineSearch }>()
);

export const setDisciplinesResultsDataByBenefit = createAction(
  '[Disciplines state] Set Disciplines Results Data',
  props<{
    benefit: string;
    province?: string;
    profession?: string;
    disciplinesResultsData: Discipline[];
    length?: number;
    pageIndex?: number;
    concerns: boolean;
  }>()
);

export const setSelectedDisciplineDetails = createAction(
  '[Disciplines state] Set Selected Details',
  props<{ selectedDisciplineDetails: Discipline }>()
);

export const setDisciplinesLastSearchPerformed = createAction(
  '[Disciplines state] Set last Search Performed',
  props<{
    lastSearchPerformed: LastSearchPerformed;
  }>()
);
export const setResetResultsByBenefit = createAction(
  '[Disciplines state] Set Reset saved Results'
);
